<template>
  <ul class="dropdown-section">
    <slot></slot>
  </ul>
</template>

<style lang="scss" scoped>
  $module: 'dropdown-section';

  .#{$module} {
    border-top: 1px solid var(--color-grey-300);

    &:empty {
      display: none;
    }
  }
</style>
