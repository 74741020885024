// eslint-disable-next-line max-classes-per-file
import { IsArray, IsEnum, IsNumber, IsOptional, IsString, IsUUID } from 'class-validator'

import { SORT_ORDER } from '../../../@enums/common.enums'
import { GET_SUPPLIERS_SORT_FIELDS } from '../../../@enums/suppliers.enums'

import type { BaseSupplier } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSuppliersDTO {
  @IsNumber()
  year!: number

  @IsOptional()
  @IsEnum(GET_SUPPLIERS_SORT_FIELDS)
  sortField?: GET_SUPPLIERS_SORT_FIELDS

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsOptional()
  @IsString()
  query?: string

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  activities?: string[]

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  statuses?: string[]

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  accuracyScores?: string[]

  @IsArray()
  @IsUUID(4, { each: true })
  @IsOptional()
  cohortIds?: string[]

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export type GetSuppliersDTORes = {
  suppliers: BaseSupplier[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}

export const GET_SUPPLIERS_SUB_PATH = ''
export const GET_SUPPLIERS_PATH = `${SUPPLIERS_CONTROLLER}`
