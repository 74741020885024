<template>
  <svg
    :width="width"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.7752 11.5417C8.7752 11.5417 9.79187 13.625 11.5002 13.4667C14.9669 13.15 14.7335 8.54167 16.0002 6.91667C14.9277 6.3833 13.7441 6.1113 12.5464 6.12288C11.3486 6.13447 10.1705 6.42932 9.10854 6.98334C7.31687 8.00001 7.19187 9.70834 8.7752 11.5417Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.225 9.43335C11.225 9.43335 8.975 9.70835 7.375 13.6"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.625 1.12501C10.0681 1.12328 8.53905 1.53779 7.19618 2.3256C5.85332 3.11341 4.74546 4.24587 3.98735 5.60572C3.22923 6.96557 2.84842 8.50336 2.88434 10.0598C2.92026 11.6163 3.37162 13.1349 4.19167 14.4583L1.625 19.875L7.01667 17.3083C8.16721 18.0228 9.46789 18.4605 10.8163 18.5868C12.1647 18.7132 13.5241 18.5248 14.7873 18.0366C16.0506 17.5483 17.1833 16.7734 18.0962 15.773C19.0091 14.7726 19.6773 13.5739 20.0482 12.2714C20.4191 10.9688 20.4826 9.59795 20.2336 8.26669C19.9847 6.93543 19.4301 5.68013 18.6136 4.59962C17.7971 3.51911 16.7409 2.64289 15.5282 2.03995C14.3155 1.43701 12.9793 1.12381 11.625 1.12501V1.12501Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width?: number
  }

  withDefaults(defineProps<Props>(), {
    width: 20,
  })
</script>

<style lang="scss" scoped>
  $module: 'dashboard-icon';

  .#{$module} {
    display: block;

    path {
      stroke: var(--color-grey-600);
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
