<template>
  <router-link
    class="sidenav-item"
    :to="{ ...to, params: { activeOrgId, ...(to.params || {}) } }"
    :class="{ 'is-disabled': disabled }"
  >
    <div :class="['sidenav-item__content', { columns: icon }]">
      <component
        :is="icon"
        v-if="icon"
        :width="20"
        class="sidenav-item__icon"
      />
      <span
        class="sidenav-item__label"
        data-ci="sidenav-item-label"
      >
        {{ label }}
      </span>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
  import { type Component, computed } from 'vue'

  import type { RouterLink } from './SideNav.constants'

  import { useOrganizationStore } from '@/client/store/organization.pinia'

  type Props = {
    to: RouterLink
    label: string
    disabled?: boolean
    icon?: Component
  }

  defineProps<Props>()

  const activeOrgId = computed(() => {
    const orgStore = useOrganizationStore()
    if (!orgStore.activeOrganization?.id) {
      console.warn('Cannot find active org id for sidenav link')
    }
    return orgStore.activeOrganization?.id
  })
</script>

<style lang="scss" scoped>
  $module: 'sidenav-item';

  .#{$module} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-kelp-300);
    font-weight: 500;
    padding: $grid-size-padding * 3 $grid-size-padding * 4 $grid-size-padding * 3 44px;
    border-radius: $radius;
    margin-bottom: $grid-size-margin;

    &:hover {
      &:deep(svg) {
        fill: var(--color-grey-0);
      }
    }

    &.is-disabled {
      color: var(--color-grey-600);
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.router-link-active,
    &:hover {
      background-color: var(--color-kelp-default);
      color: var(--color-grey-0);
    }

    &.router-link-active {
      &:deep(svg) {
        fill: white;
      }
    }

    &__content {
      &.columns {
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-items: center;
      }
    }

    &__icon {
      margin-right: $grid-size-margin * 2;
    }

    &__label {
      font-size: $font-size-6;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:deep(svg) {
      fill: var(--color-kelp-300);
    }
  }
</style>
