<template>
  <Dropdown
    :isDropdownOpen="isExpanded"
    :isSearchable="true"
    :activeSearchTerm="activeSearchTerm"
    :minWidthItems="false"
    searchPlaceholder="Search business unit"
    class="business-unit-dropdown"
    data-ci="business-unit-dropdown"
    @onOpen="isExpanded = true"
    @onClose="isExpanded = false"
    @update:activeSearchTerm="e => (activeSearchTerm = e)"
  >
    <template v-slot:trigger>
      <div class="business-unit-dropdown__btn">
        <div
          v-if="selectedBusinessUnit"
          class="business-unit-dropdown__bu-name-and-logo"
        >
          <img
            v-if="selectedBusinessUnit?.orgLogo"
            class="business-unit-dropdown__logo"
            :src="selectedBusinessUnit?.orgLogo"
            :alt="`${selectedBusinessUnit?.name} logo`"
          />
          <span
            v-else
            class="business-unit-dropdown__empty-logo"
          >
            {{ abbreviateName(selectedBusinessUnit.name) }}
          </span>

          <EllipsisText
            class="business-unit-dropdown__truncate"
            :noWrap="true"
            :title="selectedBusinessUnit.name"
          >
            {{ selectedBusinessUnit?.name }}
          </EllipsisText>
        </div>
        <div class="business-unit-dropdown__icons">
          <UpDownChevronsIcon />
        </div>
      </div>
    </template>

    <li :class="['business-unit-dropdown__root-org', { 'has-children': !!filteredBuList.length }]">
      <RecursiveBUItem
        :orgStructure="[rootOrg]"
        :maxNestingLevel="0"
        :rootOrgId="rootOrg.id"
      >
        <template v-slot:right="rightSlotProps">
          <BuDropdownAction
            :org="rightSlotProps.org"
            @onSelected="setActiveBusinessUnit"
          />
        </template>
      </RecursiveBUItem>
    </li>
    <li>
      <RecursiveBUItem
        :orgStructure="filteredBuList"
        :maxNestingLevel="4"
        :rootOrgId="rootOrg.id"
        @onSelected="setActiveBusinessUnit"
      >
        <template v-slot:right="rightSlotProps">
          <BuDropdownAction
            :org="rightSlotProps.org"
            @onSelected="setActiveBusinessUnit"
          />
        </template>
      </RecursiveBUItem>
    </li>
  </Dropdown>
</template>

<script setup lang="ts">
  import { onUpdated, ref, toRef } from 'vue'
  import { useRouter } from 'vue-router'

  import Dropdown from '@components/designsystem/Dropdown/Dropdown.vue'
  import EllipsisText from '@components/EllipsisText/EllipsisText.vue'
  import UpDownChevronsIcon from '@components/icons/UpDownChevronsIcon.vue'
  import RecursiveBUItem from '@components/buDropdown/RecursiveBUItem.vue'
  import BuDropdownAction from '@components/buDropdown/BuDropdownAction.vue'

  import { abbreviateName } from '@lib/utilities/organizationStructureUtilities'
  import { useSearchBusinessUnitTreeByName } from '@lib/utilities/businessUnitTreeSearch/businessUnitTreeSearch'

  import type {
    BusinessUnitResponse,
    BusinessUnitResponseStructure,
  } from '@/imports/@types/organizationStructure/v2/OrganizationStructure'

  type Props = {
    rootOrg: BusinessUnitResponseStructure
    selectedBusinessUnit: BusinessUnitResponseStructure | null
    businessUnits: BusinessUnitResponse
  }

  const props = defineProps<Props>()
  const router = useRouter()

  // Logic for dropdown
  const isExpanded = ref(false)
  const activeSearchTerm = ref('')
  const { filteredBuList } = useSearchBusinessUnitTreeByName(toRef(props, 'businessUnits'), activeSearchTerm)

  onUpdated(() => {
    if (!isExpanded.value) {
      activeSearchTerm.value = ''
    }
  })

  const setActiveBusinessUnit = (businessUnitId: string) => {
    isExpanded.value = false

    if (!businessUnitId) {
      console.error('No business unit id provided')
      return
    }

    // Push a new url with the selected business unit. The rest are handled by the router beforeEach guard
    const currentRouteName = router.currentRoute.value.name
    router.push({
      name: currentRouteName,
      params: {
        activeOrgId: businessUnitId,
      },
    })
  }
</script>

<style scoped lang="scss">
  $module: 'business-unit-dropdown';

  .#{$module} {
    width: 100%;
    display: block;

    &__btn {
      border: 1px solid rgba(128, 173, 175, 0.24);
      display: flex;
      color: var(--color-grey-50);
      font-weight: 500;
      align-items: center;
      justify-content: space-between;
      padding: $grid-size-padding * 2;
      border-radius: 6px;
      width: 100%;

      &:hover,
      &:focus {
        background-color: var(--color-kelp-default);
      }
    }

    &__bu-name-and-logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: $font-size-6;
      font-weight: 500;
      width: calc(100% - 16px);
    }

    &__dropdown-head {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__head-btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__root-org {
      &.has-children {
        border-bottom: 1px solid var(--color-grey-50);
        margin-bottom: $grid-size-margin;
      }
    }

    &__logo {
      width: 28px;
      height: 28px;
      margin-right: $grid-size-margin;
      border-radius: 4px;
    }

    &__empty-logo {
      @extend .#{$module}__logo;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      background-color: var(--color-grey-50);
      color: var(--color-grey-900);
      width: 28px;
      height: 28px;
      flex-shrink: 0;
    }

    &__icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: $grid-size-margin;

      :deep(path) {
        fill: var(--color-grey-0);
      }
    }

    &__globe-icon {
      margin: 0 $grid-size-margin;
      color: var(--color-grey-600);
    }

    &__dropdown-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 320px;
      overflow: visible;
    }

    &__truncate {
      white-space: nowrap;
    }

    :deep(.dropdown__items-wrap) {
      width: 450px;
    }

    :deep(.dropdown__items) {
      max-height: 300px;
    }

    :deep(.dropdown__trigger) {
      width: 100%;
    }
  }

  :deep(.aq-btn__txt) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
</style>
