<template>
  <component
    :is="element"
    :class="[
      'aq-card',
      {
        'big-padding': bigPadding,
        'no-padding': !includePadding,
        'small-padding': smallPadding,
        'smallest-padding': smallestPadding,
        'no-border': removeBorder,
        'include-margin-bottom': includeMarginBottom,
      },
      theme,
    ]"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
  type Props = {
    element?: string
    bigPadding?: boolean
    smallPadding?: boolean
    smallestPadding?: boolean
    includePadding?: boolean
    includeMarginBottom?: boolean
    theme?: 'blue' | 'ghost' | 'grey' | ''
    removeBorder?: boolean
  }

  withDefaults(defineProps<Props>(), {
    element: 'div',
    includePadding: true,
    theme: '',
  })
</script>

<style lang="scss" scoped>
  $module: 'aq-card';

  .#{$module} {
    border: 1px solid var(--color-grey-100);
    background-color: white;
    border-radius: 8px;
    padding: $grid-size-padding * 6;
    position: relative;

    &.big-padding {
      padding: 42px 36px 60px;
    }

    &.small-padding {
      padding: $grid-size-padding * 4;
    }

    &.smallest-padding {
      padding: $grid-size-padding * 3;
    }

    &.no-padding {
      padding: 0;
    }

    &.include-margin-bottom {
      margin-bottom: $grid-size-margin * 3;
    }

    &.blue {
      background: var(--color-blue-100);
      border: 1px solid rgba(128, 173, 175, 0.25);
    }

    &.ghost {
      background: transparent;
      border: 1px solid var(--color-grey-300);
    }

    &.grey {
      background: var(--color-grey-50);
    }

    &.no-border {
      border: 0;
    }
  }
</style>
