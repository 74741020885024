import { IsArray, IsEnum, IsNumber, IsOptional, IsString, IsUUID } from 'class-validator'

import { SORT_ORDER } from '../../../@enums/common.enums'
import { GET_SUPPLIERS_STATUSES } from '../../../@enums/suppliers.enums'
import { GET_PRODUCT_FOOTPRINT_REQUESTS_SORT_FIELDS_V2 } from '../../../@enums/supplier-product-footprint.enums'

import type { SupplierProductRequest } from '../../../@types/suppliers.types'

import { SUPPLIER_PRODUCT_REQUESTS_CONTROLLER } from './supplier-product-requests.common-vars'

export class GetProductRequestsDto {
  @IsNumber()
  year!: number

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number

  @IsOptional()
  @IsEnum(GET_PRODUCT_FOOTPRINT_REQUESTS_SORT_FIELDS_V2)
  sortField?: GET_PRODUCT_FOOTPRINT_REQUESTS_SORT_FIELDS_V2

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsString()
  @IsOptional()
  searchQuery?: string

  @IsArray()
  @IsEnum(GET_SUPPLIERS_STATUSES, { each: true })
  @IsOptional()
  statuses?: GET_SUPPLIERS_STATUSES[]

  @IsOptional()
  @IsUUID()
  cohortId?: string
}

export type GetProductRequestsDtoRes = {
  requests: SupplierProductRequest[]
  meta: {
    perPage: number
    pageNo: number
    totalCount: number
  }
}

export const GET_SUPPLIER_PRODUCT_REQUESTS_SUB_PATH = ''
export const GET_SUPPLIER_PRODUCT_REQUESTS_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${GET_SUPPLIER_PRODUCT_REQUESTS_SUB_PATH}`
