<template>
  <nav
    id="sidenav"
    ref="root"
    :key="refreshTrigger"
    class="sidenav"
  >
    <RouterLink
      :to="{ name: 'dashboard' }"
      class="sidenav__logo"
    >
      <AqLogoInitials class="my-5" />
    </RouterLink>

    <BuDropdown
      v-if="rootOrg"
      :selectedBusinessUnit="activeBusinessUnit"
      :businessUnits="businessUnits"
      :rootOrg="rootOrg"
      class="mb-6"
    />

    <ul class="sidenav__groups">
      <template
        v-for="group in navList"
        :key="group.label"
      >
        <IfFeatureEnabled
          v-if="group.featureFlag"
          :flagName="group.featureFlag"
        >
          <li :data-label="group.label">
            <SideNavGroup
              :group="group"
              :activeAccordions="activeAccordions"
              :isRootOrg="isRootOrg"
              @toggleAccordion="toggleAccordion"
            ></SideNavGroup>
          </li>
        </IfFeatureEnabled>
        <li
          v-else
          :data-label="group.label"
        >
          <SideNavGroup
            :group="group"
            :activeAccordions="activeAccordions"
            :isRootOrg="isRootOrg"
            @toggleAccordion="toggleAccordion"
          ></SideNavGroup>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
  import { computed, shallowRef, onBeforeMount, watch, ref, onBeforeUnmount, nextTick, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  import { isString } from 'lodash-es'

  import AqLogoInitials from '@components/icons/AqLogoInitials.vue'
  import BuDropdown from '@components/buDropdown/BuDropdown.vue'

  import { showToast } from '@lib/plugins/Toastie'
  import featureFlagService from '@lib/services/featureFlagService'
  import { findActiveOrgInBusinessUnitStructure } from '@lib/utilities/findActiveOrgInBusinessUnitStructure/findActiveOrgInBusinessUnitStructure'

  import { ROLE_KEYS } from '@lib/constants/permission/permissionConstants'

  import SideNavGroup from './SideNavGroup.vue'

  import { getNavList } from './navLists/index'

  import type {
    BusinessUnitResponse,
    BusinessUnitResponseStructure,
  } from '@/imports/@types/organizationStructure/v2/OrganizationStructure'

  import { useOrganizationStore } from '@/client/store/organization.pinia'
  import { useUserStore } from '@/client/store/user.pinia'

  // org data from store
  const orgStore = useOrganizationStore()
  const userStore = useUserStore()

  const activeOrgId = computed(() => orgStore.activeOrganization?.id || '')

  const navList = shallowRef<ReturnType<typeof getNavList>>([])
  const route = useRoute()
  const orgStructure = computed(() => orgStore.orgStructure)
  const businessUnits = ref<BusinessUnitResponse>([])
  const rootOrg = ref<BusinessUnitResponseStructure | null>(null)
  const refreshTrigger = ref(0)

  const isSuperUser = computed(() => userStore.isSuperUser)
  const userRole = computed(() => orgStore.userRole)
  const isInternalUser = computed(() => isSuperUser.value || userRole.value === ROLE_KEYS.SOLUTION_ADVISOR)
  const isRootOrg = computed(
    () => !!(orgStore.activeOrganization && orgStore.activeOrganization.id === orgStore.activeOrganization.rootOrgId),
  )

  const isSupplyChainEngagementEnabled = computed(() => featureFlagService.isEnabled('supply-chain-engagement'))

  /**
   * We can forcible convert this type because it would be impossible to reach this state
   * without there being an active business unit
   */
  const activeBusinessUnit = ref<BusinessUnitResponseStructure | null>(null)

  onBeforeMount(async () => {
    refreshNav()
    featureFlagService.subscribeToContext(refreshNav)

    await loadData()

    /**
     * The active organization structure does not match the business unit structure.
     * So, we can't just use the active org as our active BU, we have to find that org
     * in our business unit structure and manually set it.
     */
    watch(activeOrgId, async () => {
      loadData()
    })

    watch(
      orgStructure,
      () => {
        loadData()
      },
      { deep: true },
    )

    watch(
      () => route.path,
      (path, oldPath) => {
        if (path !== oldPath) {
          setActiveGroup()
        }
      },
    )
  })

  onBeforeUnmount(() => {
    featureFlagService.unsubscribeToContext(refreshNav)
  })

  const loadData = async () => {
    try {
      rootOrg.value = orgStructure.value[0]
      businessUnits.value = orgStructure.value[0].children || []

      activeBusinessUnit.value = findActiveOrgInBusinessUnitStructure(
        rootOrg.value,
        orgStore.activeOrganization?.id || '',
      )

      setActiveGroup()
    } catch (err) {
      if (isString(err)) {
        showToast({
          type: 'is-danger',
          message: err,
        })
      }

      console.error(err)
    }
  }

  const orgModules = computed(() => orgStore.enabledModules || [])

  const refreshNav = () => {
    navList.value = getNavList({
      orgType: orgStore.activeOrganization?.configuration.clientType || 'full',
      orgModules: orgModules.value,
      isInternalUser: isInternalUser.value,
      isSupplyChainEngagementEnabled: isSupplyChainEngagementEnabled.value,
    })

    /**
     * The update of a feature flag is not immediately obvious by the shape of the array so
     * Vue is not able to trigger a re-render unless we can find a valid key. This can be
     * optimised by doing extra work to figure out which groups have changed, and restricting
     * re-rendering to them entirely.
     */
    refreshTrigger.value++
  }

  const root = ref<HTMLElement | null>(null)

  const setActiveGroup = async () => {
    await nextTick()
    if (!root.value) return
    const activeLi = root.value.querySelector('li:has(.router-link-exact-active,.router-link-active)')
    if (!activeLi) return

    if (!activeAccordions.value[activeLi.dataset.label]) {
      toggleAccordion(activeLi.dataset.label)
    }
  }

  onMounted(setActiveGroup)
  // Accordion
  const activeAccordions = ref<{ [key: string]: boolean }>({})

  const toggleAccordion = (accordionId: string) => {
    const isOpen = activeAccordions.value[accordionId]

    activeAccordions.value = isOpen ? {} : { [accordionId]: true }
  }
</script>

<style lang="scss" scoped>
  $module: 'sidenav';
  $nav-padding: 0 20px;
  $caption-font-size: 12px;
  $sidenav-padding: 20px;
  $logoPlusBuDropdownHeight: (128 + 48 + 32) * 1px;

  .#{$module} {
    padding: 0 $sidenav-padding;
    background: var(--color-kelp-900);
    left: 0;
    height: 100vh;
    width: 250px;
    position: fixed;
    z-index: 10;

    &__logo {
      display: block;
    }

    &__sticky {
      background: var(--color-grey-50);
      position: sticky;
      top: 0;
      z-index: 11;
      padding-top: $sidenav-padding;
    }

    &__separator {
      border-bottom: 1px solid var(--color-grey-300);
      padding-bottom: 16px;
      opacity: 0;
      transition: 0.3s;

      &.visible {
        opacity: 1;
        margin-bottom: 10px;
      }
    }

    &__groups {
      height: calc(100% - $logoPlusBuDropdownHeight);
      overflow: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__caption {
      font-size: $caption-font-size;
      color: var(--color-grey-400);
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;
      transition: 0.3s;
      margin-bottom: 8px;
    }
  }
</style>
