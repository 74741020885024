import { CALC_METHOD } from '../../../@enums/calc-method-code.enums'

import { TAGS_CONTROLLER } from './controller.common-vars'

export type GetTagOptionsDTORes = {
  calcMethodCode: CALC_METHOD
  isDeprecated: boolean
  tagCategory: string
  tagName: string
}

export const GET_TAG_OPTIONS_PATH = 'tag-options'
export const GET_TAG_OPTIONS_URI = `${TAGS_CONTROLLER}/${GET_TAG_OPTIONS_PATH}`
