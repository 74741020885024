import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator'

import type { SupplierCohortDetailed } from '../../../@types/supplier-cohorts'

import { SORT_ORDER } from '../../../@enums/common.enums'

import { GET_SUPPLIER_COHORTS_DETAILED_SORT_FIELDS } from '../../../@enums/supplier-cohorts.enums'

import { SUPPLIER_COHORTS_CONTROLLER } from './supplier-cohorts.common-vars'

export class GetSupplierCohortsDetailedDTO {
  @IsNumber()
  year!: number

  @IsOptional()
  @IsString()
  searchQuery?: string

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsOptional()
  @IsEnum(GET_SUPPLIER_COHORTS_DETAILED_SORT_FIELDS)
  sortField?: GET_SUPPLIER_COHORTS_DETAILED_SORT_FIELDS
}

export type GetSupplierCohortsDetailedDTORes = {
  supplierCohorts: SupplierCohortDetailed[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}

export const GET_SUPPLIER_COHORTS_DETAILED_SUB_PATH = 'detailed'
export const GET_SUPPLIER_COHORTS_DETAILED_PATH = `${SUPPLIER_COHORTS_CONTROLLER}/${GET_SUPPLIER_COHORTS_DETAILED_SUB_PATH}`
