export enum BASIC_FACILITY_LIST_SORT_BY {
  FACILITY_NAME = 'facilityName',
}

export enum BASIC_FACILITY_DATA_STATUS {
  DATA_REQUIRED = 'DATA_REQUIRED',
  CALCULATING = 'CALCULATING',
  CALCULATED = 'CALCULATED',
  NO_DATA_UPLOADED = 'NO_DATA_UPLOADED',
}

export enum BASIC_FACILITY_CALCULATION_STATUSES {
  IN_PROGRESS = 'in_progress',
  PUBLISHED = 'published',
  INGESTED = 'ingested',
  INGESTION_ERROR = 'ingestion_error',
  EXTERNAL_ERROR = 'external_error',
}

export enum BASIC_FACILITY_STATUSES_AGGREGATE_TYPES {
  YEAR = 'YEAR',
  OVERALL = 'OVERALL',
}
