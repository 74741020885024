<template>
  <span
    class="aq-tag"
    :class="classes"
  >
    <span
      v-if="activity?.activityArea"
      class="aq-tag__background"
      :class="[`has-background-${colors[activity.activityArea]}`]"
    />

    <span
      v-if="backgroundColor"
      class="aq-tag__background"
      :class="[`has-background-${backgroundColor}`]"
    />

    <span class="aq-tag__foreground">
      <CategoryIcon
        v-if="activity.activityArea"
        class="mr-1"
        :category="activity.activityArea"
        :categoryItem="activity.activity || activity.activityName"
        :includePadding="false"
        :includeBackground="false"
      />
      <span class="aq-tag__content">
        <slot name="content" />

        <button
          v-if="isRemoveBtnVisible"
          class="ml-1"
          type="button"
          @click="$emit('onRemove')"
        >
          <i class="fas fa-times aq-tag__remove" />
        </button>
      </span>
    </span>
  </span>
</template>

<script lang="ts">
  import { defineComponent, type PropType } from 'vue'

  import CategoryIcon from '@reinga/CategoryIcon/CategoryIcon.vue'

  import { SCOPE_OR_ACTIVITY_AREA_COLOR_NAMES } from '@/imports/lib/constants/categoryIconConstants'

  export default defineComponent({
    name: 'Tag',

    components: {
      CategoryIcon,
    },

    props: {
      state: {
        type: String,
        default: '',
      },

      variant: {
        type: String,
        default: '',
      },

      activity: {
        type: Object,
        default: () => ({}),
      },

      isRounded: {
        type: Boolean,
        default: false,
      },

      includeBorderRadius: {
        type: Boolean,
      },

      isRemoveBtnVisible: {
        type: Boolean,
        default: false,
      },

      backgroundColor: {
        type: String,
        default: '',
      },

      size: {
        type: String as PropType<'s' | 'm'>,
        default: 'm',
      },

      isMedium: {
        type: Boolean,
      },

      isBold: {
        type: Boolean,
      },

      isTag: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      hasCustomBackground(): string | undefined {
        return this.activity?.activityArea
      },

      classes(): string[] {
        const classes = []

        if (this.state) {
          classes.push(`aq-tag--${this.state}`)
        }

        if (this.variant) {
          classes.push(`aq-tag--${this.variant}`)
        }

        if (this.hasCustomBackground) {
          classes.push('aq-tag--no-background')
        }

        if (this.includeBorderRadius) {
          classes.push('aq-tag--border-radius')
        }

        if (this.isRounded) {
          classes.push('is-rounded')
        }

        if (this.size === 's') {
          classes.push('size-s')
        }

        if (this.isMedium) {
          classes.push('medium')
        }

        if (this.isBold) {
          classes.push('bold')
        }

        if (this.isTag) {
          classes.push('tag')
        }

        return classes
      },

      colors() {
        return SCOPE_OR_ACTIVITY_AREA_COLOR_NAMES
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'aq-tag';

  .#{$module} {
    background-color: #e6f7ff;
    color: var(--color-blue-700);
    font-size: 14px;
    line-height: math.div(24, 14);
    padding: 4px 12px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    font-weight: 500;

    &.is-rounded {
      border-radius: 9999px;
    }

    &.size-s {
      font-size: $font-size-7;
      padding: $grid-size-padding $grid-size-padding * 2;
      line-height: math.div(18, $font-size-7-unitless);
    }

    &.medium {
      font-weight: 500;
    }

    &.bold {
      font-weight: 700;
    }

    &__remove {
      color: var(--color-grey-600);
      font-weight: 400;
    }

    // states
    &--success {
      background: var(--color-green-50);
      color: #1d6857;

      &.tag {
        border: 1px solid #1d6857;
      }
    }

    &--error {
      background-color: var(--color-red-50);
      color: var(--color-red-700);

      &.tag {
        border: 1px solid var(--color-red-700);
      }
    }

    &--warning {
      background-color: rgba(#fe7a11, 0.1);
      color: var(--color-amber-500);

      &.tag {
        border: 1px solid var(--color-amber-500);
      }
    }

    &--ghost {
      background: var(--color-grey-0);
    }

    &--grey {
      color: var(--color-grey-600);
      background-color: var(--color-grey-50);

      &.tag {
        border: 1px solid var(--color-text-primary);
      }
    }

    &--dark-grey {
      color: var(--color-grey-900);
      background-color: var(--color-grey-100);
      font-weight: 500;

      &.tag {
        border: 1px solid var(--color-grey-900);
      }
    }

    &--blue {
      background-color: var(--color-blue-100);
      color: var(--color-blue-700);

      &.tag {
        border: 1px solid var(--color-blue-700);
      }
    }

    &--orange {
      background-color: var(--color-amber-50);
      color: var(--color-amber-700);

      &.tag {
        border: 1px solid var(--color-amber-700);
      }
    }

    &--amber {
      background-color: var(--color-amber-50);
      color: var(--color-amber-700);

      &.tag {
        border: 1px solid var(--color-amber-700);
      }
    }

    &--light-blue {
      background-color: var(--color-blue-100);
      color: var(--color-blue-500);

      &.tag {
        border: 1px solid var(--color-blue-500);
      }
    }

    &--purple {
      background-color: var(--color-royal-blue-100);
      color: var(--color-royal-blue-800);

      &.tag {
        border: 1px solid var(--color-royal-blue-800);
      }
    }

    &--waste {
      @extend .#{$module}--purple;
    }

    &--transportation {
      background-color: var(--color-bg-activity-transportation-light);
      color: var(--color-seagull-800);
    }

    &--use-of-products {
      background-color: var(--color-bg-activity-use-of-products-light);
      color: var(--color-bg-activity-use-of-products);
    }

    &--purchases {
      background-color: var(--color-bg-activity-purchases-light);
      color: var(--color-bg-activity-purchases);
    }

    // variants
    &--small {
      font-size: 12px;
      padding: 4px 8px;
    }

    &--entity, // type of tag on a file upload
    &--country {
      @extend .#{$module}--small;

      background-color: var(--color-grey-50);
      color: var(--color-grey-600);
    }

    &--activity {
      @extend .#{$module}--small;

      color: var(--color-grey-900);
      font-weight: 500;
    }

    &--facility,
    &--business-unit,
    &--to-do {
      color: var(--color-grey-600);
      background-color: var(--color-blue-100);
    }

    &--active {
      color: var(--color-green-700);
      background-color: var(--color-green-50);
    }

    &--inactive {
      color: var(--color-red-700);
      background-color: var(--color-red-50);
    }

    &--no-background {
      background-color: transparent;
    }

    &--border-radius {
      border-radius: 6px;
    }

    &--v-grey {
      background-color: var(--color-grey-50);
      color: var(--color-grey-600);
    }

    &--v-green {
      background-color: var(--color-green-50);
      color: var(--color-green-700);
    }

    &--v-orchid {
      background-color: var(--color-lavender-100);
      color: var(--color-lavender-800);
    }

    &--v-purple {
      background-color: var(--color-royal-blue-100);
      color: var(--color-royal-blue-800);
    }

    :deep(.icon) {
      width: auto;
      height: auto;
    }

    &__background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.2;
    }

    &__foreground {
      position: relative;
      display: flex;
    }

    &__content {
      display: flex;
      align-items: center;
    }
  }
</style>
