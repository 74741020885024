<template>
  <section class="responsiveWarning has-text-grey-8">
    <div class="columns flexColumns p-8">
      <div class="column">
        <img
          class="mt-4 is-hidden-mobile"
          src="/altruistiq-logo-aurora.svg"
          alt="Altruistiq Logo"
          width="300px"
        />
        <img
          class="mt-4 is-hidden-tablet-only"
          src="/altruistiq-logo-aurora.svg"
          alt="Altruistiq Logo"
          width="240px"
        />
      </div>

      <div class="column is-vcentered is-justify-content-center is-align-items-center is-flex-grow-4 is-flex">
        <div class="columns">
          <div class="column is-offset-2 is-8">
            <Icon
              class="computerIcon mb-5"
              icon="desktop"
              pack="fal"
              type="is-grey-8"
            />

            <p class="is-size-4 has-text-grey-8">
              Altruistiq currently requires a larger screen, please switch to a laptop or computer.
            </p>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="columns">
          <div class="column has-text-left is-offset-2 is-4">
            Need help? Contact us at<br />
            <a
              class="auroraLink has-text-aurora is-underlined"
              href="mailto:support@altruistiq.com"
            >
              support@altruistiq.com
            </a>
          </div>

          <div class="column has-text-left is-offset-1 is-4">
            Learn more about us at<br />
            <a
              class="underline has-text-aurora is-underlined"
              href="https://altruistiq.com"
            >
              altruistiq.com
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
  import Icon from '@components/Icon.vue'
</script>

<style scoped lang="scss">
  .flexColumns {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
  }

  .responsiveWarning {
    position: absolute;
    background-color: var(--color-kelp-default);
    margin: 0;
    text-align: center;
    z-index: 9999;
    width: 100vw;
    height: 100%;

    :deep(.icon) {
      display: initial;
    }

    @media (min-width: 1024px) {
      display: none;
    }

    @media print {
      display: none;
    }
  }

  .computerIcon {
    font-size: 60px;
  }
</style>
