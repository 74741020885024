// eslint-disable-next-line max-classes-per-file
import { IsBoolean, IsOptional, IsString, IsUUID, ValidateIf } from 'class-validator'

import { PRODUCT_FOOTPRINTS_CONTROLLER } from '../controller.common-vars'

import { ValidateDTO } from '../..'

import { BASIC_FACILITY_DATA_STATUS } from '@/imports/@enums/basic-facilities.enums'

// Product single snapshot

// [GET] /products/footprints/:productId/facilities/:facilityId/snapshots/:snapshotId
// [GET] /products/footprints/:productId/bus/:buId/snapshots/:snapshotId
export const PRODUCT_SNAPSHOT_FACILITY_SUBPATH = ':productId/facilities/:facilityId/snapshots/:snapshotId'
export const PRODUCT_SNAPSHOT_FACILITY_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_SNAPSHOT_FACILITY_SUBPATH}`

export const PRODUCT_SNAPSHOT_BU_SUBPATH = ':productId/bus/:buId/snapshots/:snapshotId'
export const PRODUCT_SNAPSHOT_BU_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_SNAPSHOT_BU_SUBPATH}`

export class SnapshotDetailsDTOReq extends ValidateDTO {
  @IsString()
  productId!: string

  @ValidateIf(f => !f.buId)
  @IsUUID()
  facilityId?: string

  @ValidateIf(f => !f.facilityId)
  @IsUUID()
  buId?: string

  @IsUUID()
  snapshotId!: string | 'latest'

  @IsBoolean()
  @IsOptional()
  includeBasicPCFStatuses?: boolean
}

export type SubActivities = {
  activityName: string
  supplier: string
  quantityPerUnit: number
  quantityUnit: string
  emissions: number
  emissionsUnit: string
  emissionFactor: number
  status?: BASIC_FACILITY_DATA_STATUS
}

export type Activities = {
  activityAreaName: string
  emissions: number
  emissionsUnit: string // changed from emissionsUnit
  emissionFactor: number
  subActivities: SubActivities[]
  status?: BASIC_FACILITY_DATA_STATUS
}

export type SnapshotDetailsDTORes = {
  activities: Activities[]
  totalEmissions: number
  totalEmissionUnit: string
  status?: BASIC_FACILITY_DATA_STATUS
}
