import { IsNumber, IsOptional } from 'class-validator'

import { type SupplierAssociation } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSupplierAssociationsDTO {
  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export type GetSupplierAssociationsDTORes = {
  supplierAssociations: SupplierAssociation[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}

export const GET_SUPPLIER_ASSOCIATIONS_SUB_PATH = ':supplierId/associations'
export const GET_SUPPLIER_ASSOCIATIONS_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIER_ASSOCIATIONS_SUB_PATH}`
