<template>
  <div
    class="aq-help-text"
    :class="[sizeClass, { success, 'small-padding': smallPadding }]"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'AqHelpText',

    props: {
      size: {
        type: String,
        default: 'medium',
        // Our design system specifies medium and large as size types for inputs
        validator(value: string) {
          return ['medium', 'large'].includes(value)
        },
      },

      success: {
        type: Boolean,
      },

      smallPadding: {
        type: Boolean,
      },
    },

    computed: {
      // Returns the classNames defined by the required size.
      // Size prop defaults to medium
      sizeClass() {
        const { size } = this

        if (size === 'large') return 'is-size-8'

        return 'is-size-9'
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'aq-help-text';

  .#{$module} {
    color: var(--color-grey-600);
    padding: $grid-size-padding * 2 0 0;

    &.success {
      color: var(--color-green-700);
    }

    &.small-padding {
      padding: $grid-size-padding 0 0;
    }
  }
</style>
