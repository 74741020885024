import type { AxiosInstance } from 'axios'

import { TAG_TYPES } from '@/imports/@enums/tags.enum.v2'

import { GET_TAG_OPTIONS_URI } from '@/imports/lib/DTO/tags/get-aq-tags'
import type { CALC_METHOD } from '@/imports/@enums/calcMethodCode.enum'

// apis for tags schemaa
export const useTagsApi = (http: AxiosInstance) => ({
  // get AQ tags
  getAqTags(orgId: string, calcMethods: CALC_METHOD[], tagTypes: TAG_TYPES[]) {
    return http.post<{ [key: string]: string[] }>(
      GET_TAG_OPTIONS_URI,
      { calcMethods, tagTypes },
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },
})
