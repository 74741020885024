<template>
  <svg
    :class="['chevron', direction]"
    :width="calculatedWidth"
    :height="calculatedHeight"
    viewBox="0 0 9 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.839966 1.41L5.41997 6L0.839966 10.59L2.24997 12L8.24997 6L2.24997 0L0.839966 1.41Z"
      :fill="color"
    />
  </svg>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  type Props = {
    width?: string | number
    direction?: 'left' | 'right' | 'down' | 'up'
    color?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    width: 9,
    direction: 'right',
    color: 'var(--color-grey-900)',
  })

  /**
   * If the chevron is pointing left or right use the width
   * passed in the prop. If the chevron is pointing up or down
   * return null and instead use the width prop to define the
   * height of the svg
   */
  const calculatedWidth = computed(() => {
    return ['left', 'right', 'down', 'up'].includes(props.direction) ? props.width : undefined
  })

  const calculatedHeight = computed(() => {
    return !['left', 'right', 'down', 'up'].includes(props.direction) ? props.width : undefined
  })
</script>

<style lang="scss" scoped>
  $module: 'chevron';

  .#{$module} {
    transform-origin: center;
    transition: transform 0.05s linear;

    &.left {
      transform: rotate(180deg);
    }

    &.up {
      transform: rotate(-90deg);
    }

    &.down {
      transform: rotate(90deg);
    }
  }
</style>
