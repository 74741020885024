import ReduceImpactLogo from '@components/icons/ReduceImpactIcon.vue'
import AnalyseIcon from '@components/icons/AnalyseIcon.vue'
import DashboardIcon from '@components/icons/DashboardIcon.vue'
import EngageSuppliersIcon from '@components/icons/EngageSuppliersIcon.vue'
import ManageDataIcon from '@components/icons/ManageDataIcon.vue'

import type { NavGroup } from '@/imports/@types/Navigation'

export const getPCFBusinessEnterpriseList = (): NavGroup[] => [
  {
    label: 'Dashboard',
    icon: DashboardIcon,
    to: {
      name: 'dashboard',
    },
  },
  {
    label: 'Manage Data',
    icon: ManageDataIcon,
    children: [
      {
        label: 'Corporate Data',
        to: {
          name: 'corporateData',
        },
      },

      {
        label: 'Product Data',
        to: {
          name: 'ProductsUpload',
        },
      },

      {
        label: 'Import PCFs',
        to: {
          name: 'pcfListTab',
        },
      },

      {
        label: 'Organisation Structure',
        to: {
          name: 'organization-structure',
        },
      },

      {
        label: 'Facility Structure',
        to: {
          name: 'facilities',
        },
      },
    ],
  },
  {
    label: 'Analyse',
    icon: AnalyseIcon,
    children: [
      {
        label: 'Products',
        to: {
          name: 'products',
        },
      },

      {
        label: 'Explore Data',
        to: {
          name: 'MainAnalyticsCollection',
        },
      },
    ],
  },
  {
    label: 'Reduce Impact',
    icon: ReduceImpactLogo,
    children: [
      {
        label: 'Product Initiatives',
        to: {
          name: 'Initiatives List',
        },
      },
    ],
  },
  {
    label: 'Engage Partners',
    icon: EngageSuppliersIcon,
    children: [
      {
        label: 'Customers',
        to: {
          name: 'customersEngagement',
        },
        featureFlag: 'enable_customers_engagement_page',
      },
    ],
  },
]
