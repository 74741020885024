<template>
  <div class="info-bubble">
    <span class="info-bubble__icon far fa-question-circle"></span>
    <div
      class="info-bubble__copy p-2 is-size-8"
      :class="float"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'InfoBubble',

    props: {
      float: {
        type: String,
        default: 'right',
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'info-bubble';

  .#{$module} {
    position: relative;
    color: var(--color-grey-400);

    &__copy {
      position: absolute;
      top: -$grid-size-margin;
      width: 300px;
      max-width: 95vw;
      min-height: 58px;
      box-shadow: 1px 1px 3px rgba(black, 0.2);
      background-color: var(--color-grey-100);
      color: var(--color-grey-600);
      display: none;
      z-index: 2;

      &.right {
        left: calc(100% + $grid-size-margin);
      }

      &.left {
        right: calc(100% + $grid-size-margin);
      }

      .#{$module}:hover & {
        display: block;
      }
    }
  }
</style>
