<template>
  <svg
    :width="width"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.875 7.375V4.875C17.875 4.54348 17.7433 4.22554 17.5089 3.99112C17.2745 3.7567 16.9565 3.625 16.625 3.625H7.875V2.375C7.875 2.04348 7.7433 1.72554 7.50888 1.49112C7.27446 1.2567 6.95652 1.125 6.625 1.125H2.875C2.54348 1.125 2.22554 1.2567 1.99112 1.49112C1.7567 1.72554 1.625 2.04348 1.625 2.375V14.7083C1.6297 15.0487 1.75681 15.376 1.98307 15.6304C2.20933 15.8847 2.5196 16.0491 2.85711 16.0934C3.19463 16.1377 3.53681 16.059 3.82106 15.8717C4.1053 15.6844 4.31259 15.401 4.405 15.0733L6.36417 8.27833C6.43937 8.01779 6.59727 7.78878 6.81403 7.62583C7.03079 7.46289 7.29466 7.37485 7.56583 7.375H19.125C19.3166 7.37493 19.5057 7.41892 19.6776 7.50355C19.8495 7.58819 19.9996 7.71121 20.1164 7.86312C20.2332 8.01502 20.3135 8.19174 20.3511 8.37962C20.3887 8.5675 20.3826 8.76151 20.3333 8.94667"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.375 14.875C10.375 16.2011 10.9018 17.4729 11.8395 18.4105C12.7771 19.3482 14.0489 19.875 15.375 19.875C16.7011 19.875 17.9729 19.3482 18.9105 18.4105C19.8482 17.4729 20.375 16.2011 20.375 14.875C20.375 13.5489 19.8482 12.2771 18.9105 11.3395C17.9729 10.4018 16.7011 9.875 15.375 9.875C14.0489 9.875 12.7771 10.4018 11.8395 11.3395C10.9018 12.2771 10.375 13.5489 10.375 14.875Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.8749 16.125H3.03906"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.375 17.375V12.375"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.375 12.375L13.5 14.25"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.375 12.375L17.25 14.25"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width?: number
  }

  withDefaults(defineProps<Props>(), {
    width: 20,
  })
</script>

<style lang="scss" scoped>
  $module: 'dashboard-icon';

  .#{$module} {
    display: block;

    path {
      stroke: var(--color-grey-600);
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
