import { IsNumber } from 'class-validator'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSuppliersMetricsDTO {
  @IsNumber()
  year!: number
}

export const GET_SUPPLIERS_METRICS_SUB_PATH = 'metrics'
export const GET_SUPPLIERS_METRICS_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIERS_METRICS_SUB_PATH}`
