import { IsArray, IsEnum, IsNumber, IsOptional, IsString, IsUUID, IsBoolean } from 'class-validator'

import { SORT_ORDER } from '../../../@enums/common.enums'
import { GET_SUPPLIERS_FOR_COHORTS_SORT_FIELDS } from '../../../@enums/suppliers.enums'

import type { SupplierForCohort } from '../../../@types/suppliers.types'

import { SUPPLIERS_CONTROLLER } from './suppliers.common-vars'

export class GetSuppliersForCohortsDTO {
  @IsNumber()
  year!: number

  @IsOptional()
  @IsEnum(GET_SUPPLIERS_FOR_COHORTS_SORT_FIELDS)
  sortField?: GET_SUPPLIERS_FOR_COHORTS_SORT_FIELDS

  @IsOptional()
  @IsUUID()
  cohortId?: string

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsOptional()
  @IsString()
  query?: string

  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  activities?: string[]

  @IsBoolean()
  @IsOptional()
  onlySelected?: boolean

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export type GetSuppliersForCohortsDTORes = {
  suppliers: SupplierForCohort[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}

export const GET_SUPPLIERS_FOR_COHORTS_SUB_PATH = 'for-cohorts'
export const GET_SUPPLIERS_FOR_COHORTS_PATH = `${SUPPLIERS_CONTROLLER}/${GET_SUPPLIERS_FOR_COHORTS_SUB_PATH}`
