import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { TOTAL_PRODUCT_QUANTITY_CONTROLLER } from './controller.common-vars'

export const GET_TOTAL_PRODUCT_QUANTITY_ERROR_COUNT_SUBPATH = 'error-count'
export const GET_TOTAL_PRODUCT_QUANTITY_ERROR_COUNT_URI = `${TOTAL_PRODUCT_QUANTITY_CONTROLLER}/${GET_TOTAL_PRODUCT_QUANTITY_ERROR_COUNT_SUBPATH}`

export class TotalProductQuantityErrorCountDTO extends ValidateDTO {
  @IsUUID()
  orgId!: string
}

export type TotalProductQuantityErrorCountRes = {
  errorCount: number
}
