import React from 'react'
import {
  KnockProvider,
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
  FilterStatus,
} from '@knocklabs/react'
import '@knocklabs/react/dist/index.css'
import './styles.css'

export const NotificationInbox = ({ userId, publicKey, feedId, userToken, onUserTokenExpiring }) => {
  const [isVisible, setIsVisible] = React.useState(false)
  const notifButtonRef = React.useRef(null)

  const SetFeedPopoverVisibility = React.useCallback(() => {
    // skipcq: JS-0123 - Shadowing is safe here
    setIsVisible(isVisible => {
      return !isVisible
    })
  }, [setIsVisible])

  const feedClientOptions = React.useMemo(() => {
    return {
      page_size: 20,
      auto_manage_socket_connection: true,
      auto_manage_socket_connection_delay: 2500,
    }
  }, [])

  return (
    <KnockProvider
      apiKey={publicKey}
      userId={userId}
      userToken={userToken}
      onUserTokenExpiring={onUserTokenExpiring}
    >
      <KnockFeedProvider
        feedId={feedId}
        defaultFeedOptions={feedClientOptions}
      >
        <NotificationIconButton
          ref={notifButtonRef}
          onClick={SetFeedPopoverVisibility}
          badgeCountType="unseen"
        />
        <NotificationFeedPopover
          buttonRef={notifButtonRef}
          isVisible={isVisible}
          onClose={SetFeedPopoverVisibility}
          initialFilterStatus={FilterStatus.Unread}
          closeOnClickOutside
        />
      </KnockFeedProvider>
    </KnockProvider>
  )
}
