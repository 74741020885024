<template>
  <Dropdown
    class="nav-bar-menu"
    :closeOnRouteChange="true"
  >
    <template v-slot:trigger>
      <div
        class="nav-bar-menu"
        data-ci="navbar-dropdown-btn"
      >
        <div class="nav-bar-menu__initial">
          <button class="is-size-6 has-text-weight-medium">
            <AqUserIcon
              :user="user"
              avatarSize="40px"
            />
          </button>
        </div>
      </div>
    </template>

    <DropdownItem>
      <router-link
        class="nav-bar-menu__item"
        :to="{ name: 'userOrganizationSettings' }"
        data-ci="navbar-user-settings-btn"
      >
        Profile settings
      </router-link>
    </DropdownItem>

    <DropdownSection>
      <HasPermissions :permissions="[ACTION.CREATE_NEW_USERS]">
        <DropdownItem class="nav-bar-menu__dropdown">
          <router-link
            class="nav-bar-menu__item"
            :to="{ name: 'manageUsers' }"
            data-ci="navbar-manage-team-btn"
          >
            Manage users
          </router-link>
        </DropdownItem>
      </HasPermissions>

      <DropdownItem v-if="currentOrgIsRootOrg && canEditOrg">
        <router-link
          class="nav-bar-menu__item"
          :to="editOrgRoutePath"
          data-ci="navbar-edit-org-btn"
        >
          Organisation settings
        </router-link>
      </DropdownItem>
    </DropdownSection>

    <DropdownSection>
      <DropdownItem v-if="showOrganizationsLink">
        <router-link
          class="nav-bar-menu__item"
          :to="{ name: 'organizations' }"
          data-ci="navbar-your-org-btn"
        >
          Your organisations
        </router-link>
      </DropdownItem>
      <IfFeatureEnabled flagName="enable-audit-logs">
        <DropdownItem>
          <router-link
            class="nav-bar-menu__item"
            :to="{ name: 'audit-logs' }"
          >
            Audit Logs
          </router-link>
        </DropdownItem>
      </IfFeatureEnabled>
    </DropdownSection>

    <DropdownSection>
      <DropdownItem>
        <button
          class="nav-bar-menu__item-logout"
          data-ci="navbar-dropdown-logout-btn"
          @click="$emit('logout')"
        >
          Logout
        </button>
      </DropdownItem>
    </DropdownSection>
  </Dropdown>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  import Dropdown from '@components/designsystem/Dropdown/Dropdown.vue'
  import AqUserIcon from '@components/designsystem/AqUserIcon/AqUserIcon.vue'
  import DropdownItem from '@components/DropdownItem.vue'
  import DropdownSection from '@components/DropdownSection.vue'
  import HasPermissions from '@components/HasPermissions.vue'

  import PermissionService from '@lib/services/permissionService'

  import { useOrganizationStore } from '@/client/store/organization.pinia'
  import { useUserStore } from '@/client/store/user.pinia'
  import { ACTION } from '@/imports/lib/constants/permission/permissionConstants'

  import { type User } from '@/imports/@types/User'

  type Props = {
    user: User
    organization: string
    showOrganizationsLink: boolean
  }

  withDefaults(defineProps<Props>(), {
    organization: '',
  })

  defineEmits<{
    (e: 'logout'): void
  }>()

  const orgStore = useOrganizationStore()
  const userStore = useUserStore()

  const organizationConfiguration = computed(() => orgStore.organizationConfiguration)
  const currentOrgIsRootOrg = computed(() => orgStore.currentOrgIsRootOrg)

  const isSuperUser = computed(() => userStore.isSuperUser)

  const isSupplierOrg = computed(() => organizationConfiguration.value?.clientType === 'supplier')

  const canEditOrg = computed(() => {
    return (
      isSupplierOrg.value ||
      isSuperUser.value ||
      PermissionService.hasPermissions([
        ACTION.UPDATE_ORGANISATION_DETAILS,
        ACTION.VIEW_CONTRACT_SCOPE_SETTINGS,
        ACTION.UPDATE_CONTRACT_SCOPE_SETTINGS,
      ])
    )
  })

  const editOrgRoutePath = computed(() => {
    if (isSupplierOrg.value) return { name: 'supplierSettings' }

    // TODO: RBAC remove this.isSuperUser check once SOLUTION_ADVISOR role is enabled
    if (
      isSuperUser.value
      // || PermissionService.hasPermissions([ACTION.VIEW_CONTRACT_SCOPE_SETTINGS, ACTION.UPDATE_CONTRACT_SCOPE_SETTINGS])
    ) {
      return { name: 'editContractScope' }
    }

    return { name: 'editDetailsAndSettings' }
  })
</script>

<style lang="scss" scoped>
  $module: 'nav-bar-menu';

  .#{$module} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: $radius;

    &__text {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      line-height: math.div(20, 14);
      padding-right: 16px;
    }

    &__organization {
      color: var(--color-kelp-300);
      font-size: 14px;
    }

    &__item,
    &__item-logout {
      color: var(--color-grey-900);
      font-size: inherit;
      padding: 0;
      margin: 0;
      display: block;
      outline: none;

      li:hover &,
      &:focus {
        color: var(--color-kelp-default);
      }
    }

    &__item-logout {
      color: var(--color-red-500);

      li:hover &,
      &:focus {
        color: var(--color-red-500);
      }
    }

    :deep(.dropdown__items-wrap) {
      left: unset;
      white-space: nowrap;
      min-width: 225px;
    }

    :deep(.dropdown__items) {
      overflow: auto;
    }
  }
</style>
