<template>
  <div v-if="userToken || knockChannelId || publicKnockKey">
    <ReactNotificationInbox
      :publicKey="publicKnockKey"
      :feedId="knockChannelId"
      :userId="userId"
      :userToken="userToken"
      :onUserTokenExpiring="regenerateKnockUserToken"
    />
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'
  import { createRoot } from 'react-dom/client'
  import { setVeauryOptions, applyPureReactInVue } from 'veaury'

  import { NotificationInbox } from '@/imports/react_app/NotificationInbox'
  import { KNOCK_PUBLIC_KEY, KNOCK_CHANNEL_ID } from '@/client/helpers'

  setVeauryOptions({
    react: {
      createRoot,
    },
  })

  const ReactNotificationInbox = applyPureReactInVue(NotificationInbox)

  const props = defineProps<{
    userId: string
    userToken: string
    regenerateKnockUserToken: () => Promise<string | undefined>
  }>()

  const publicKnockKey = computed(() => KNOCK_PUBLIC_KEY())
  const knockChannelId = computed(() => KNOCK_CHANNEL_ID())
  const regenerateKnockUserToken = props.regenerateKnockUserToken

  const userId = computed(() => props.userId)
  const userToken = computed(() => props.userToken)
</script>
