export enum GET_SUPPLIER_COHORTS_SORT_FIELDS {
  NAME = 'name',
  CREATED_AT = 'createdAt',
}

export enum GET_SUPPLIER_COHORTS_DETAILED_SORT_FIELDS {
  NAME = GET_SUPPLIER_COHORTS_SORT_FIELDS.NAME,
  CREATED_AT = GET_SUPPLIER_COHORTS_SORT_FIELDS.CREATED_AT,
  TOTAL_EMISSIONS = 'totalEmissions',
}
