<template>
  <div
    data-ci="sidenav-group"
    class="sidenav-group"
  >
    <button
      class="sidenav-group__nav-item-wrapper mb-1"
      :class="{ 'sidenav-group__single-nav-link--active': !hasChildren && isActive }"
      data-ci="sidenav-group-trigger"
      @click="onGroupParentClick(group)"
    >
      <div class="sidenav-group__accordion-name">
        <component
          :is="group.icon"
          class="mr-1"
        ></component>
        <span>{{ group.label }}</span>
      </div>

      <Chevron
        v-if="hasChildren"
        :direction="isActive ? 'up' : 'down'"
        :width="8"
        class="sidenav-group__chevron"
      ></Chevron>
    </button>

    <Accordion
      v-if="group.children"
      direction="vertical"
      :open="isActive"
      :fullWidth="true"
      class="sidenav-group__children"
    >
      <template
        v-for="child in group.children"
        :key="child.label"
      >
        <ul>
          <IfFeatureEnabled
            v-if="child.featureFlag && satisfiesMetaConditions(child)"
            :flagName="child.featureFlag"
          >
            <li>
              <SideNavLink
                v-tippy="
                  child.meta?.isBeta && {
                    content: () => 'This feature is currently in early access',
                    placement: 'left',
                  }
                "
                :to="child.to"
                :label="child.label"
              />
            </li>
          </IfFeatureEnabled>

          <li v-if="!child.featureFlag && satisfiesMetaConditions(child)">
            <SideNavLink
              :to="child.to"
              :label="child.label"
            />
          </li>
        </ul>
      </template>
    </Accordion>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'
  import { useRouter } from 'vue-router'

  import PermissionService from '@services/permissionService'

  import Accordion from '@components/designsystem/Accordion/Accordion.vue'
  import Chevron from '@components/icons/Chevron.vue'
  import IfFeatureEnabled from '@components/IfFeatureEnabled.vue'
  import SideNavLink from '@components/SideNav/SideNavLink.v2.vue'

  import type { NavGroup } from '@/imports/@types/Navigation'
  import { useOrganizationStore } from '@/client/store/organization.pinia'

  const props = defineProps<{
    group: NavGroup
    activeAccordions: { [key: string]: boolean }
    isRootOrg?: boolean
  }>()

  const emit = defineEmits<{ (e: 'toggleAccordion', id: string): void }>()

  const orgStore = useOrganizationStore()
  const router = useRouter()
  const toggleAccordion = (id: string) => emit('toggleAccordion', id)

  const hasChildren = computed(() => props.group.children?.length)

  const isActive = computed(() => props.activeAccordions[props.group.label])

  const satisfiesMetaConditions = (child: NavGroup['children'][0]) => {
    if (!child.meta) return true

    const isRootOrgSatisfied = child.meta.needsRootOrg ? props.isRootOrg : true

    const isPermissionMet =
      !child.meta.needsPermissions || PermissionService.hasPermissions(child.meta.needsPermissions)
    const isOrgModuleEnabled =
      !child.meta.requiredOrgModule || orgStore.enabledModules?.includes(child.meta.requiredOrgModule)

    const isPermissionsSatisfied = isPermissionMet && isOrgModuleEnabled

    return isRootOrgSatisfied && isPermissionsSatisfied
  }

  const onGroupParentClick = (group: NavGroup) => {
    if (hasChildren.value) {
      toggleAccordion(group.label)
    } else {
      router.push({
        name: group.to?.name,
      })
    }
  }
</script>

<style lang="scss" scoped>
  $module: 'sidenav-group';

  .#{$module} {
    &__nav-item-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--color-kelp-300);
      border-radius: $radius;
      padding: $grid-size-padding * 3 $grid-size-padding * 4;
      font-weight: 500;
      width: 100%;
      font-size: $font-size-6;

      &:hover {
        background-color: var(--color-kelp-default);
        color: var(--color-grey-50);
      }
    }

    &__accordion-name {
      display: flex;
      align-items: center;

      :deep(path) {
        stroke: currentColor;
      }
    }

    &__chevron:deep(path) {
      fill: currentColor;
    }

    &__single-nav-link {
      &--active {
        background-color: var(--color-kelp-default);
        color: var(--color-grey-50);
      }
    }
  }
</style>
