export const isDev = (): boolean => !!import.meta.env.DEV

export const getApiUrl = (): string => import.meta.env.VITE_API_URL || ''

export const rootUrl = (): string => import.meta.env.BASE_URL || ''

export const isE2ETesting = (): boolean => !!import.meta.env.VITE_IS_E2ETESTING

export const env = (): 'production' | 'staging' | 'dev' => {
  if (isDev()) return 'dev'
  if (getApiUrl().includes('altruistiq.com')) return 'production'
  if (getApiUrl().includes('staging')) return 'staging'
  return 'dev'
}

export const INTERCOM_APP_ID = (): string | undefined => import.meta.env.VITE_INTERCOM_APP_ID

export const LAUNCHDARKLY_CLIENT_ID = (): string | undefined => import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID

export const MAX_ACCEPTABLE_LENGTH_OF_SUPPLIER_INVITE_PERSONAL_MESSAGE = 1000

export const KNOCK_PUBLIC_KEY = (): string | undefined => import.meta.env.VITE_KNOCK_PUBLIC_KEY
export const KNOCK_CHANNEL_ID = (): string | undefined => import.meta.env.VITE_KNOCK_CHANNEL_ID
