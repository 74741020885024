<template>
  <svg
    :class="['aq-logo-initials', color]"
    :width="width"
    :height="height"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.25 23.9781C23.25 29.8202 26.171 33.9536 31.9848 33.9536C37.7985 33.9536 40.7196 29.8202 40.7196 23.9781C40.7196 18.136 37.7985 14.0026 31.9848 14.0026C26.171 14.0026 23.25 18.136 23.25 23.9781ZM31.9848 17.0371C35.4021 17.0371 37.2739 19.6532 37.2739 23.9781C37.2739 28.303 35.4021 30.9191 31.9848 30.9191C28.5674 30.9191 26.6957 28.303 26.6957 23.9781C26.6957 19.6532 28.5674 17.0371 31.9848 17.0371ZM32.3606 29.5792H35.3951C35.4731 29.5792 35.5369 29.5225 35.5298 29.4445V26.41C35.5298 26.3391 35.4731 26.2753 35.3951 26.2753H32.3606C32.2897 26.2753 32.2259 26.332 32.2259 26.41V29.4445C32.2259 29.5154 32.2826 29.5792 32.3606 29.5792ZM40.9749 35.1589H37.9404C37.8624 35.1589 37.8057 35.0951 37.8057 35.0242V31.9897C37.8057 31.9117 37.8695 31.855 37.9404 31.855H40.9749C41.0529 31.855 41.1096 31.9188 41.1096 31.9897V35.0242C41.1167 35.1022 41.0529 35.1589 40.9749 35.1589ZM11.4088 29.8131C11.4372 29.7351 11.5152 29.6784 11.6002 29.6784H19.4134C19.4913 29.6784 19.5764 29.728 19.6048 29.8131L19.8813 30.6426L20.7888 33.5353C20.8172 33.6133 20.8952 33.67 20.9519 33.67H24.1211C24.2274 33.67 24.2841 33.5637 24.2558 33.4786L18.1655 14.4138C18.1372 14.3358 18.0592 14.2791 18.0025 14.2791H13.0395C12.9899 14.2791 12.9048 14.3287 12.8764 14.4138L6.75782 33.4786C6.72946 33.5566 6.77909 33.67 6.89253 33.67H10.0617C10.1114 33.67 10.1964 33.6488 10.2248 33.5353L11.2458 30.3378L11.4088 29.8131ZM12.5645 26.6439C12.4794 26.6439 12.4227 26.5943 12.451 26.5092L15.3721 17.4199C15.4288 17.2852 15.5919 17.2852 15.6486 17.4199L18.5484 26.5092C18.5767 26.5872 18.5484 26.6439 18.4633 26.6439H12.5645Z"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'AqLogoInitials',

    props: {
      height: {
        type: Number,
        default: 48,
      },

      width: {
        type: Number,
        default: 48,
      },

      color: {
        type: String,
        default: 'aurora',
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'aq-logo-initials';

  .#{$module} {
    &.aurora path {
      fill: var(--color-aurora-300);
    }

    &.sahara path {
      fill: var(--color-sahara-300);
    }
  }
</style>
