import { getDefaultNav } from './FullAndSelfServe'
import { getSupplierNav, getProductFootPrintStartTierNav } from './Suppliers'
import { getInternalUserNav } from './InternalUsers'
import { getPCFBusinessEnterpriseList } from './PCFBusinessEnterprise'

import type { NavGroup, GetNavListParams } from '@/imports/@types/Navigation'
import { ORGANIZATION_MODULES } from '@/imports/@enums/organizations.enums'

export const getNavList = ({
  orgType,
  orgModules,
  isInternalUser,
  isSupplyChainEngagementEnabled,
}: GetNavListParams): NavGroup[] => {
  const initialList: NavGroup[] = isInternalUser ? getInternalUserNav() : []

  if (['supplier'].includes(orgType) && orgModules?.includes(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER)) {
    return getProductFootPrintStartTierNav().concat(initialList)
  }

  if (['supplier'].includes(orgType)) {
    return getSupplierNav().concat(initialList)
  }

  if (orgModules?.includes(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER) && isSupplyChainEngagementEnabled) {
    return getProductFootPrintStartTierNav().concat(initialList)
  }

  if (orgModules?.includes(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER)) {
    return getPCFBusinessEnterpriseList().concat(initialList)
  }

  return getDefaultNav().concat(initialList)
}
