<template>
  <svg
    :width="width"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.625 18.625H20.375"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 9.875H3.5C3.33424 9.875 3.17527 9.94085 3.05806 10.0581C2.94085 10.1753 2.875 10.3342 2.875 10.5V18.625H6.625V10.5C6.625 10.3342 6.55915 10.1753 6.44194 10.0581C6.32473 9.94085 6.16576 9.875 6 9.875Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.25 2.375H9.75C9.58424 2.375 9.42527 2.44085 9.30806 2.55806C9.19085 2.67527 9.125 2.83424 9.125 3V18.625H12.875V3C12.875 2.83424 12.8092 2.67527 12.6919 2.55806C12.5747 2.44085 12.4158 2.375 12.25 2.375Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 6.125H16C15.8342 6.125 15.6753 6.19085 15.5581 6.30806C15.4408 6.42527 15.375 6.58424 15.375 6.75V18.625H19.125V6.75C19.125 6.58424 19.0592 6.42527 18.9419 6.30806C18.8247 6.19085 18.6658 6.125 18.5 6.125Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts" setup>
  type Props = {
    width?: number
  }

  withDefaults(defineProps<Props>(), {
    width: 20,
  })
</script>

<style lang="scss" scoped>
  $module: 'dashboard-icon';

  .#{$module} {
    display: block;

    path {
      stroke: var(--color-grey-600);
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
