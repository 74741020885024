import { isObject } from 'lodash-es'

import type { Role } from './Role'
import type { DataSourceUploadType } from './DataSource'
import type { AUTH_MODES } from './AuthModes'
import type { User } from './User'

import type { ORGANIZATION_INTERNAL_TYPES } from '@/imports/@enums/organizations.enums'
import { ORGANIZATION_MODULES } from '@/imports/@enums/organizations.enums'

/**
 * There is already a LegalEntity definition that was
 * created during the organization structure v1 work.
 * This does not match that definition.
 */
export type OrgLegalEntity = {
  country: string
  legalEntityName: string
  regNumber: string
  taxNumber: string
}

export type OrganizationMetric = {
  [key: string]: number | null
  averageCustomerDistance: number
  averageCustomerEmissions: number
  averageCustomerOrders: number
  averageCustomerRevenue: number | null
  totalCustomerDistance: number
  totalCustomerEmissions: number
  totalCustomerOrders: number
  totalCustomerRevenue: number | null
  totalCustomers: number
}

export type OrganizationMetrics = {
  [key: number | string]: OrganizationMetric
}

export type ServiceSupportLevel = 'standard' | 'premium' | 'premium-plus' | undefined

export type Authentication = {
  mode: AUTH_MODES
  enforceTwoFactorAuth?: boolean
  isInvitingExternalUsersEnabled?: boolean
  isSSOEntitlementControlledViaAQ?: boolean
  samlCertificate: string
  samlEndpointUrl: string
  samlIdentityProvider: string
}

export enum PREFERRED_SCOPE_ACCOUNTING_METHODS {
  MARKET_BASED = 'market_based',
  LOCATION_BASED = 'location_based',
}

export interface PartialOrganization {
  id: string
  name: string
  logo: string
  configuration: {
    sessionTimeout: number
    currency: string
    settings: {
      currency: string
    }
    locationDetails?: {
      countryCode: string
      address: string
      city: string
      apt?: string
      postalCode: string
    }
    clientType: string
    baselineYear: number
    customPipelines: number
    serviceSupportLevel?: ServiceSupportLevel
    enabledModules: {
      [value in ORGANIZATION_MODULES]: boolean
    }
    serviceAddons: {
      downstreamS3EmissionsMeasurement: boolean
      flagEmissionsMeasurement: boolean
    }
    contractScope: string
    legalEntities?: OrgLegalEntity[]
    accessiblePages: {
      [key: string]: string | undefined
    }
    intensityMetrics: string[]
    financialYearSettings: {
      useCalendarYear: boolean
      financialYearStartsAt: string
    }
    productUploadType?: DataSourceUploadType
    internalType?: ORGANIZATION_INTERNAL_TYPES
    preferredScope2AccountingMethod?: PREFERRED_SCOPE_ACCOUNTING_METHODS
  }
  authentication: Authentication
  roles: Role[]
  rootOrgId: string
  asanaCompanyId?: string
  asanaOwnerId?: string
  createdAt: string
}

export interface Organization extends PartialOrganization {
  type: string
  onboarding: unknown
  organizationMetrics: OrganizationMetrics
  roles: Role[]
  availableYears: number[]
  availableProductYears: number[]
  features: string[]
  asanaCompanyId: string
  asanaOwnerId: string
}

export const isOrganization = (payload: unknown): payload is Organization =>
  isObject(payload) && 'roles' in payload && !!payload?.roles

export interface UnknownSupplierOrgDetails {
  supplierName: string
  supplierVatNumber: string | null
  supplierCountryCode: string
  supplierRegistrationId: string | null
}

export type RequestAccessOrgDetails = Pick<PartialOrganization, 'name' | 'logo' | 'configuration'>

export type RequestAccessAdminDetails = Pick<User, 'id' | 'userName' | 'email' | 'avatar'>
