import type { NavigationGuard, NavigationGuardNext, RouteLocationNormalized, RouteRecordNormalized } from 'vue-router'

import type { ACTION } from '@lib/constants/permission/permissionConstants'

import { ROLE_KEYS } from '@/imports/lib/constants/permission/permissionConstants'

import { Api } from '@/imports/lib/services/api.service'
import FeatureFlagService from '@/imports/lib/services/featureFlagService'
import PermissionService from '@/imports/lib/services/permissionService'

import { useOrganizationStore } from '@/client/store/organization.pinia'
import { useUserStore } from '@/client/store/user.pinia'
import { setIntendedUrlPath } from '@/imports/lib/utilities/urlRedirector'
import { ORGANIZATION_MODULES } from '@/imports/@enums/organizations.enums'

/**
 * @type {{ADMIN: string, SA: string}}
 */
const ROLES = {
  ADMIN: ROLE_KEYS.ADMIN,
  SA: ROLE_KEYS.SOLUTION_ADVISOR,
}

/**
 * Check if a user has one or more permissions based on their role
 * - If 1 persmission is passed, it must match to continue routing
 * - If >1 permission is passed, it continues routing when at least 1 permission passes
 */
export const hasPermissions =
  (permissions: ACTION | ACTION[]): NavigationGuard =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (PermissionService.hasPermissions(permissions)) return next()

    return next({ name: 'noAccess', params: to?.params })
  }

const userHasRoles = (roles: string[]): boolean => {
  const orgStore = useOrganizationStore()

  return roles.includes(orgStore.userRole)
}

export const initializeSession = async (isPublic: boolean): Promise<boolean> => {
  const sessionToken = localStorage.getItem('sessionToken')
  const userStore = useUserStore()

  const userId = userStore.id

  if (!userId) {
    if (!sessionToken) return false

    const orgStore = useOrganizationStore()

    try {
      const { data: session } = await Api.user.resumeSession(sessionToken)

      if (session) {
        userStore.setSession({ session })

        await orgStore.getUserOrganizations()
        await orgStore.setActiveOrganizationById()
      }
    } catch (e) {
      console.warn('Cannot initialize session', e)
      if (isPublic) return true
      localStorage.removeItem('sessionToken')
      return false
    }
  }

  return true
}

export const preventUnauthorizedNavigation = async (to: RouteLocationNormalized) => {
  const isSessionInitialized = await initializeSession(to.meta.public)

  if (to.meta.public) return null

  if (!isSessionInitialized) {
    setIntendedUrlPath(to)
    return { name: 'login' }
  }

  const orgStore = useOrganizationStore()

  const currentRole = orgStore.userRole

  if (currentRole === ROLE_KEYS.NO_ACCESS) {
    setIntendedUrlPath(to)
    return { name: 'request-access' }
  }

  return null
}

export const checkForUserRole: NavigationGuard = (to, from, next) => {
  const userStore = useUserStore()
  const userId = userStore.user.id

  if (userId) return next()
  return next({ name: 'login' })
}

export const checkForAdminRole: NavigationGuard = (to, from, next) => {
  const userStore = useUserStore()
  const orgStore = useOrganizationStore()

  const userRole = orgStore.userRole
  const isSuperUser = userStore.isSuperUser

  if ([ROLE_KEYS.ADMIN, ROLE_KEYS.SUPPLIER_ADMIN].includes(userRole as ROLE_KEYS) || isSuperUser) {
    return next()
  }

  return next({ name: 'index' })
}

export const checkForSuperuserRole: NavigationGuard = (to, from, next) => {
  const userStore = useUserStore()
  const isSuperUser = userStore.isSuperUser

  if (isSuperUser) return next()

  return next({ name: 'index' })
}

export const checkForAdvisorRole: NavigationGuard = (to, from, next) => {
  const userStore = useUserStore()
  const isSuperUser = userStore.isSuperUser

  if (isSuperUser) return next()

  if (userHasRoles([ROLES.SA])) return next()

  return next({ name: 'index' })
}

export const checkForEnabledFeatures: NavigationGuard = (to, from, next) => {
  const requiredFlags = to.meta?.requiredFeatureFlags as string[]
  if (typeof requiredFlags === 'undefined') return next()

  const hasFeature = requiredFlags.some(flag => FeatureFlagService.isEnabled(flag))

  if (hasFeature) return next()

  return next({ name: 'index' })
}

export const checkForAllEnabledFeatures: NavigationGuard = (to, from, next) => {
  const requiredFlags = to.meta?.requiredFeatureFlags as string[]
  if (typeof requiredFlags === 'undefined') return next()

  const hasFeature = requiredFlags.every(flag => FeatureFlagService.isEnabled(flag))

  if (hasFeature) return next()

  return next({ name: 'index' })
}

export const checkForOrgModuleEnabled =
  (requiredOrgModule: ORGANIZATION_MODULES): NavigationGuard =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const orgStore = useOrganizationStore()

    const moduleEnabled = orgStore.enabledModules.includes(requiredOrgModule)
    if (moduleEnabled) return next()

    return next({ name: 'index' })
  }

export const checkForMultipleOrgModuleEnabled =
  (requiredOrgModules: ORGANIZATION_MODULES[]): NavigationGuard =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const orgStore = useOrganizationStore()

    const modulesEnabled = requiredOrgModules.some(module => orgStore.enabledModules?.includes(module))
    if (modulesEnabled) return next()

    return next({ name: 'index' })
  }

export const checkIsActiveOrgRootOrg: NavigationGuard = (to, from, next) => {
  const orgStore = useOrganizationStore()
  const { activeOrganization, rootOrgId } = orgStore

  if (!rootOrgId || !activeOrganization) return next(from)

  if (activeOrganization?.id === rootOrgId) return next()

  return next(from)
}

/**
 * Validates a request token passed through to the PDF request route
 * @returns {Promise<void>}
 */
export const checkForPDFToken: NavigationGuard = async (to, from, next) => {
  const orgStore = useOrganizationStore()

  // Also possible to access the page when logged in and an admin
  if (orgStore.userRole) return checkForAdminRole(to, from, next)

  const requestKey = to.query.requestKey
  const orgId = to.params.orgId

  if (!requestKey || !orgId) return next({ name: 'login' })

  const keyValid = await Api.call('pdfRequest.isRequestTokenValid', { requestKey, orgId })
  return keyValid?.result ? next() : next({ name: 'login' })
}

/**
 * We use this check when we want to select which of two components should be loaded
 * for a shared path e.g. when we are rolling out the new implementation of an existing feature.
 */
export const checkForFeatureFlagExpectedRoute: NavigationGuard = async (to, from, next) => {
  type featureFlagForRoute = {
    flag: string
    name: string
  }

  const { flag, name } = to.meta?.featureFlagForRoute as featureFlagForRoute

  const isFeatureEnabled = FeatureFlagService.isEnabled(flag)

  const route = {
    name,
    params: to.params,
  }

  /** If the feature flag is enabled, we load the component as specified by our new route.
   *  If not, we use the existing component as specified by the origial route.
   */
  return isFeatureEnabled ? next() : next(route)
}

export const checkIfSupplier: NavigationGuard = (to, from, next) => {
  const orgStore = useOrganizationStore()

  const { isSupplier } = orgStore

  return isSupplier ? next() : next({ name: 'index' })
}

export const checkIfNotSupplier: NavigationGuard = (to, from, next) => {
  const orgStore = useOrganizationStore()

  const { isSupplier } = orgStore

  return !isSupplier ? next() : next({ name: 'index' })
}

export const checkIfBasic: NavigationGuard = (to, from, next) => {
  const orgStore = useOrganizationStore()

  const { isBasic } = orgStore

  return isBasic ? next() : next({ name: 'index' })
}

export const updateOrgStoreBasedOnRoute = async (to: RouteLocationNormalized) => {
  const orgStore = useOrganizationStore()
  const userStore = useUserStore()

  // Check if we are navigating to an organization path
  if (!to.matched?.some((r: RouteRecordNormalized) => r.name === 'org-path')) return

  const activeOrgId = to.params.activeOrgId as string
  const loadedOrgId = orgStore?.activeOrganization?.id

  // Check if the active organization is the same as the one we are navigating to
  if (activeOrgId === loadedOrgId) return

  // Load the organization data and set it as the active organization to user store
  userStore.updateLastSelectedOrganization(activeOrgId)
  await orgStore.setActiveOrganizationById(activeOrgId)
}

export const redirectToOrgBasedUrl: NavigationGuard = (to, from, next) => {
  const orgStore = useOrganizationStore()
  const activeOrgId = orgStore?.activeOrganization?.id

  if (!activeOrgId) {
    setIntendedUrlPath(to)
    return next({ name: 'login' })
  }
  if (to.fullPath.startsWith(`/o/${activeOrgId}`)) {
    return next()
  }

  return next({ path: `/o/${activeOrgId}${to.fullPath}`, query: { ...to.query } })
}

export const redirectToLoginIfActiveOrgIsNotLoaded: NavigationGuard = (to, from, next) => {
  const orgStore = useOrganizationStore()
  if (orgStore.activeOrganization?.id) {
    return next({ name: 'dashboard', params: { activeOrgId: orgStore.activeOrganization.id } })
  }
  return next({ name: 'login' })
}
