import { IsNumber, IsOptional, IsUUID } from 'class-validator'

import { SUPPLIER_PRODUCT_REQUESTS_CONTROLLER } from './supplier-product-requests.common-vars'

export class GetPCFEmissionsCoverageDto {
  @IsNumber()
  year!: number

  @IsUUID()
  @IsOptional()
  cohortId?: string
}

export const GET_PCF_EMISSIONS_COVERAGE_SUB_PATH = 'emissions-coverage'
export const GET_PCF_EMISSIONS_COVERAGE_PATH = `${SUPPLIER_PRODUCT_REQUESTS_CONTROLLER}/${GET_PCF_EMISSIONS_COVERAGE_SUB_PATH}`
