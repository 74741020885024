<template>
  <div
    class="notification-bar mb-2"
    :class="notificationClass"
  >
    <slot name="content"></slot>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Notification',
    props: {
      variant: {
        type: String,
        default: 'danger',
      },
    },

    computed: {
      notificationClass() {
        return `notification-bar--${this.variant}`
      },
    },
  })
</script>

<style lang="scss">
  .notification-bar {
    border-radius: 6px;
    padding: 16px;

    &--danger {
      color: var(--color-red-700);
      background-color: var(--color-red-50);
    }
    &--warning {
      color: var(--color-grey-900);
      background-color: var(--color-sahara-300);
    }
    &--info {
      color: var(--color-blue-700);
      background-color: var(--color-blue-100);
    }
    &--success {
      color: var(--color-grey-0);
      background-color: var(--color-green-700);
    }

    &--ghost {
      background-color: transparent;
      color: var(--color-grey-600);
      border: 1px solid var(--color-grey-100);
    }

    &--grey {
      background-color: var(--color-grey-50);
      color: var(--color-grey-900);
      border: 1px solid var(--color-grey-50);
    }

    &--amber {
      color: var(--color-amber-700);
      background-color: var(--color-amber-50);
    }

    a {
      text-decoration: underline;
    }
  }
</style>
