import { IsNumber, IsOptional, IsUUID } from 'class-validator'

import type { SupplierCohort } from '../../../@types/supplier-cohorts'

import { SUPPLIER_COHORTS_CONTROLLER } from './supplier-cohorts.common-vars'

export class GetSupplierCohortsDTO {
  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number

  @IsOptional()
  @IsUUID()
  supplierId?: string
}

export type GetSupplierCohortsDTORes = {
  supplierCohorts: SupplierCohort[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}

export const GET_SUPPLIER_COHORTS_SUB_PATH = ''
export const GET_SUPPLIER_COHORTS_PATH = `${SUPPLIER_COHORTS_CONTROLLER}/${GET_SUPPLIER_COHORTS_SUB_PATH}`
