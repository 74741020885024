import { IsArray, IsString, IsUUID } from 'class-validator'

import { SUPPLIER_COHORTS_CONTROLLER } from './supplier-cohorts.common-vars'

export class CreateSupplierCohortDTO {
  @IsString()
  name!: string

  @IsArray()
  @IsUUID(4, { each: true })
  supplierIds!: string[]
}

export const CREATE_SUPPLIER_COHORT_SUB_PATH = ''
export const CREATE_SUPPLIER_COHORT_PATH = `${SUPPLIER_COHORTS_CONTROLLER}/${CREATE_SUPPLIER_COHORT_SUB_PATH}`
