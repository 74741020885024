import FeatureFlagService from '@lib/services/featureFlagService'

import { ACTION } from '@lib/constants/permission/permissionConstants'

import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import { updateActiveTarget } from '@lib/utilities/routeUtilities'

import { ORGANIZATION_MODULES } from '@/imports/@enums/organizations.enums'
import {
  checkForAdminRole,
  checkForAdvisorRole,
  checkForAllEnabledFeatures,
  checkForEnabledFeatures,
  checkForFeatureFlagExpectedRoute,
  checkForMultipleOrgModuleEnabled,
  checkForOrgModuleEnabled,
  checkForPDFToken,
  checkForSuperuserRole,
  checkForUserRole,
  checkIfBasic,
  checkIfNotSupplier,
  checkIfSupplier,
  checkIsActiveOrgRootOrg,
  hasPermissions,
  redirectToLoginIfActiveOrgIsNotLoaded,
  redirectToOrgBasedUrl,
} from '@/imports/lib/utilities/routeGuards'

/**
 * WARNING, DO NOT CHANGE dbKey values!
 * Add dbKey and title to meta to make the page available in the organization configuration
 * When changing, also needs updating in constants.js
 *
 *
 * NOTE: make sure to add public: true if the route is publicly accessible or session management will break!
 * NOTE: add 'dontUseAsIntendedPath' if you need to exclude a user from being redirected to that route after login,
 *   useful for getting out of infinite loops in the authentication stages (see 2fa)
 */
export default [
  /**
   * Public routes
   */
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'split-view', public: true, dontUseAsIntendedPath: true },
    component: () => import('@pages/user/Login.vue'),
    children: [
      {
        path: 'set-organization',
        name: 'setOrgToLoginTo',
        component: () => import('@pages/user/SetOrgToLoginTo.vue'),
      },
      {
        path: 'credentials',
        name: 'credentials',
        component: () => import('@pages/user/LoginWithCredentials.vue'),
      },
      {
        path: 'saml',
        name: 'saml',
        component: () => import('@pages/user/LoginWithSamlOkta.vue'),
      },
      {
        path: 'saml-azure-ad',
        name: 'samlAzureAD',
        component: () => import('@pages/user/LoginWithSamlAzure.vue'),
      },
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: () => import('@pages/user/ForgotPassword.vue'),
      },
    ],
  },
  {
    path: '/reset-password', // adding this route as safeguard
    name: 'resetPassword',
    meta: { layout: 'no-sidebar-or-navbar', public: true },
    component: () => import('@pages/user/ResetPassword.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    meta: { layout: 'no-sidebar-or-navbar', public: true },
    component: () => import('@pages/user/ResetPassword.vue'),
  },
  {
    path: '/invitation', // adding this route as safeguard
    name: 'acceptInvite',
    meta: { layout: 'split-view', public: true },
    component: () => import('@pages/user/AcceptInvite.vue'),
  },
  {
    path: '/invitation/:token',
    name: 'acceptInvite',
    meta: { layout: 'split-view', public: true },
    component: () => import('@pages/user/AcceptInvite.vue'),
  },
  {
    path: '/saml/success',
    name: 'samlSessionWaitingRoom',
    meta: { layout: 'no-sidebar-or-navbar', public: true },
    component: () => import('@pages/user/SamlSessionWaitingRoom.vue'),
  },
  {
    path: '/saml/error/',
    name: 'samlError',
    meta: { layout: 'no-sidebar-or-navbar', public: true },
    component: () => import('@pages/user/SamlError.vue'),
  },
  {
    path: '/pdf/:reportType/:orgId/:year',
    name: 'pdfReport',
    meta: {
      layout: 'no-sidebar-or-navbar',
      public: true,
      category: 'Reports',
    },
    component: () => import('@pages/pdfReport/pdfReports.vue'),
    beforeEnter: checkForPDFToken,
  },
  {
    path: '/pdf/:reportType/:orgId/:customerId/:year',
    name: 'customerReport',
    meta: {
      layout: 'pdf',
      public: true,
      category: 'Reports',
    },
    component: () => import('@pages/pdfReport/pdfReports.vue'),
    beforeEnter: checkForPDFToken,
  },
  /**
   * User routes
   */
  {
    path: '/',
    name: 'index',
    meta: { dontUseAsIntendedPath: true },
    beforeEnter: [redirectToLoginIfActiveOrgIsNotLoaded],
  },

  {
    path: '/o/:activeOrgId',
    name: 'org-path',
    redirect: { name: 'dashboard' },
    children: [
      // START OF ORG SPECIFIC PATHS
      {
        path: 'customers/generate-report',
        name: 'generateCustomerReport',
        meta: {
          layout: 'no-sidebar-or-navbar',
          public: false,
          category: 'Reports',
        },
        component: () => import('@/imports/ui/pages/customers/reports/GenerateCustomerReport.vue'),
        beforeEnter: [checkForAdminRole],
      },

      {
        path: 'measure/:breakdown?',
        name: 'measure',
        component: () => import('@pages/measure/Measure.vue'),
        beforeEnter: [
          hasPermissions([
            ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
            ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
          ]),
          checkForUserRole,
        ],
        meta: {
          pageTitle: 'Overview',
          category: 'Analyse',
        },
      },
      {
        path: 'measure/intensity-metrics',
        name: 'intensityMetrics',
        beforeEnter: [
          hasPermissions([ACTION.CREATE_INTENSITY_METRICS, ACTION.UPDATE_INTENSITY_METRICS]),
          checkForUserRole,
        ],
        component: () => import('@pages/measure/IntensityMetrics.vue'),
        meta: {
          disableYearSelector: true,
          pageTitle: 'Intensity metrics',
          category: 'Manage Data',
        },
      },
      {
        path: 'manage',
        name: 'manage',
        redirect: { name: 'Targets overview' },
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@pages/dashboard/Dashboard.vue'),
        meta: {
          category: 'Dashboard',
        },
        beforeEnter: [checkForEnabledFeatures],
      },
      {
        path: 'suppliers/products',
        name: 'supplierProducts',
        meta: {
          requiredFeatureFlags: ['enable_supplier_product_footprint'],
          pageTitle: 'Import PCFs',
          category: 'Manage Data',
        },
        component: () => {
          if (!FeatureFlagService.isEnabled('rollout-revised-product-emissions-factor-wizard')) {
            return import('@pages/supplierProducts/SupplierProductsPage.deprecated.vue')
          }

          return import('@pages/supplierProducts/SupplierProductsPage.vue')
        },
        beforeEnter: [checkForEnabledFeatures],
      },
      {
        /**
         * This path is used to introduce users to the product footprint create journey from
         * the perspective of a user who is responding to a data request. It's just an extra
         * step to explain the journey in that context.
         */
        path: 'suppliers/products/create-from-request-introduction',
        name: 'supplierCreateProductFootprintFromRequestIntroduction',
        meta: {
          layout: 'empty',
          requiredFeatureFlags: [
            'rollout-revised-product-emissions-factor-wizard',
            'enable_supplier_product_footprint',
          ],
          pageTitle: 'Create product footprint from request',
        },
        component: () =>
          import(
            '@pages/supplierProducts/CreateProductFromRequestIntroduction/CreateProductFromRequestIntroduction.vue'
          ),
        beforeEnter: [checkForAllEnabledFeatures],
      },
      {
        /**
         * This path is used to introduce users to the product footprint create journey
         */
        path: 'suppliers/products/create-introduction',
        name: 'supplierCreateProductFootprintIntroduction',
        meta: {
          layout: 'empty',
          requiredFeatureFlags: [
            'rollout-revised-product-emissions-factor-wizard',
            'enable_supplier_product_footprint',
          ],
          pageTitle: 'Create product footprint introduction',
        },
        component: () => import('@pages/supplierProducts/CreateProductIntroduction/CreateProductIntroduction.vue'),
        beforeEnter: [checkForAllEnabledFeatures],
      },
      {
        /**
         * This path opens the PCF wizard of a given ID. Used for creating and editing.
         */
        path: 'suppliers/products/:id',
        name: 'supplierProductFootprint',
        meta: {
          layout: 'empty',
          requiredFeatureFlags: [
            'rollout-revised-product-emissions-factor-wizard',
            'enable_supplier_product_footprint',
          ],
          pageTitle: 'Product footprint',
          category: 'Manage data',
        },
        component: () => import('@pages/supplierProducts/CreateProductEmissionFactor/CreateProductEmissionFactor.vue'),
        beforeEnter: [checkForAllEnabledFeatures],
      },
      {
        /**
         * @deprecated - this is the old journey for creating a product footprint and will be removed with the
         * rollout of rollout-revised-product-emissions-factor-wizard
         */
        path: 'suppliers/products/create/:id?',
        name: 'createSupplierProduct',
        meta: {
          layout: 'empty',
          requiredFeatureFlags: ['enable_supplier_product_footprint'],
          pageTitle: 'Create supplier product',
          category: 'Manage Data',
        },
        component: () => import('@pages/supplierProducts/CreateSupplierProduct.vue'),
        beforeEnter: [checkForEnabledFeatures],
        redirect: { name: 'supplierCreateProductFootprintIntroduction' },
      },
      {
        path: 'suppliers/footprints',
        name: 'supplierFootprints',
        meta: {
          requiredFeatureFlags: ['enable_supplier_footprints'],
          category: 'Manage Data',
        },
        component: () => import('@pages/supplierFootprints/SupplierFootprints.vue'),
        beforeEnter: [checkForEnabledFeatures, checkIfSupplier],
      },
      {
        path: 'suppliers/footprints/create/:id?',
        name: 'createSupplierFootprint',
        meta: {
          layout: 'empty',
          requiredFeatureFlags: ['enable_supplier_footprints'],
          category: 'Manage Data',
        },
        component: () => import('@pages/supplierFootprints/CreateSupplierFootprint.vue'),
        beforeEnter: [checkForEnabledFeatures, checkIfSupplier],
      },
      {
        path: 'suppliers/data-requests',
        name: 'supplierDataRequests',
        meta: {
          requiredFeatureFlags: ['enable-supplier-engagement', 'enable-franchise-engagement'],
          category: 'Engage Suppliers',
        },
        component: () => import('@/imports/ui/components/suppliers/dataRequests/SupplierDataRequestsPage.vue'),
        redirect: { name: 'supplierDataRequestsStats' },
        beforeEnter: [checkForEnabledFeatures],
        children: [
          {
            path: '',
            name: 'supplierDataRequestsStats',
            component: () =>
              import(
                '@/imports/ui/components/suppliers/dataRequests/pages/SupplierDataRequestStats/SupplierDataRequestsStats.vue'
              ),
            children: [
              {
                path: 'suppliers',
                name: 'suppliersDataRequestTab',
                component: () => import('@components/FranchiseEngagement/SuppliersTab/SuppliersTab.vue'),
              },
              {
                path: 'franchises',
                name: 'franchisesDataRequestTab',
                component: () => import('@components/FranchiseEngagement/FranchiseTab/FranchisesTab.vue'),
                meta: {
                  requiredFeatureFlags: ['enable-franchise-engagement'],
                },
                beforeEnter: [checkForEnabledFeatures],
              },
            ],
          },
          {
            path: 'annual-report',
            name: 'annualReport',
            component: () =>
              import('@/imports/ui/components/suppliers/dataRequests/pages/annual/AnnualSupplierRequestsReport.vue'),
            children: [
              {
                path: '',
                name: 'annualInvitedSuppliers',
                component: () =>
                  import('@/imports/ui/components/suppliers/dataRequests/pages/annual/InvitedSuppliersTab.vue'),
              },
              {
                path: 'result',
                name: 'annualAnalyseResult',
                component: () =>
                  import('@/imports/ui/components/suppliers/dataRequests/pages/annual/AnalyseResultTab.vue'),
              },
            ],
          },
          {
            path: 'product-report',
            name: 'productReport',
            component: () =>
              import('@/imports/ui/components/suppliers/dataRequests/pages/product/ProductSupplierRequestsReport.vue'),
            children: [
              {
                path: '',
                name: 'productFootprintRequests',
                component: () => {
                  if (!FeatureFlagService.isEnabled('rollout-supplier-cohorts-v1')) {
                    return import(
                      '@/imports/ui/components/suppliers/dataRequests/pages/product/ProductFootprintRequestsTab.deprecated.vue'
                    )
                  }

                  return import(
                    '@/imports/ui/components/suppliers/dataRequests/pages/product/ProductFootprintRequestsTab.vue'
                  )
                },
              },
              {
                path: 'result',
                name: 'productAnalyseResult',
                component: () =>
                  import('@/imports/ui/components/suppliers/dataRequests/pages/product/ProductAnalyseResultsTab.vue'),
              },
            ],
          },
          {
            path: 'invite',
            name: 'requestSupplierData',
            component: () => import('@/imports/ui/components/suppliers/dataRequests/SupplierRequestDataPage.vue'),
            meta: {
              layout: 'empty',
              pageTitle: 'Invite suppliers',
            },
          },
          {
            path: 'franchise-corporate-report-requests',
            name: 'franchiseCorporateRequestsTabs',
            meta: {
              requiredFeatureFlags: ['enable-franchise-engagement'],
            },
            beforeEnter: [hasPermissions(ACTION.VIEW_FRANCHISE_REQUEST)],
            component: () =>
              import('@pages/engagePartners/dataRequests/franchises/corporateRequests/FranchiseCorporateRequests.vue'),
            children: [
              {
                path: '',
                name: 'franchiseCorporateFootprintDataRequests',
                component: () =>
                  import(
                    '@pages/engagePartners/dataRequests/franchises/corporateRequests/tabs/dataRequests/FranchiseCorporateDataRequestsTab.vue'
                  ),
              },
              {
                path: 'analysis',
                name: 'franchiseCorporateFootprintAnalysis',
                component: () =>
                  import(
                    '@pages/engagePartners/dataRequests/franchises/corporateRequests/tabs/analysis/FranchiseCorporateDataAnalysisTab.vue'
                  ),
              },
            ],
          },
        ],
      },
      /**
       * We are transitioning to partner engagement nomenclature. This route
       * needs to be outside of the supplier children scope because it is
       * a full screen layout. It is therefore a good candidate for beginning
       * that transition. This inconsistency will be temporary.
       */
      {
        path: 'partner-engagement/franchise-corporate-report-bulk-requests',
        name: 'franchiseCorporateReportBulkRequests',
        meta: {
          requiredFeatureFlags: ['enable-franchise-engagement'],
          layout: 'EmptyLayout',
        },
        beforeEnter: [hasPermissions(ACTION.SEND_FRANCHISE_INVITE)],
        component: () => import('@pages/FranchiseEngagement/BulkRequestWizard/BulkRequestWizard.vue'),
      },
      /**
       * Franchisor engagement refers to the relationship between a franchis_EE
       * and a franchis_OR from the perspective of the franchisee. So I own a mcdonald's
       * franchise and want to see the requests that mcdonald's head office has sent me,
       * I'd go to these pages.
       *
       * This means they are only available to basic organsiations. At the time of
       * writing there is no easy way to differentiate between a franchise and a supplier,
       * so both will see this.
       */
      {
        path: 'partner-engagement/franchisors',
        name: 'franchisors',
        component: () => import('@pages/engagePartners/franchisorEngagement/Franchisor.vue'),
        redirect: { name: 'franchisorEngagementFranchisorsTab' },
        meta: {
          requiredFeatureFlags: ['rollout-franchisor-engagement'],
          category: 'Engage Partners',
        },
        beforeEnter: [
          hasPermissions(ACTION.VIEW_LIST_OF_ALL_SUPPLIERS),
          checkForUserRole,
          checkForEnabledFeatures,
          checkIfBasic,
        ],
        children: [
          {
            path: 'franchisors',
            name: 'franchisorEngagementFranchisorsTab',
            component: () =>
              import('@components/FranchiseEngagement/Franchisor/Tabs/FranchisorTab/FranchisorTab.hoc.vue'),
          },
          {
            path: 'data-requests',
            name: 'franchisorEngagementDataRequestsTab',
            component: () =>
              import('@components/FranchiseEngagement/Franchisor/Tabs/DataRequestTab/DataRequestTab.hoc.vue'),
          },
        ],
      },
      {
        path: 'suppliers-engagement',
        name: 'suppliersV2Page',
        component: () => import('@pages/suppliers/Suppliers.vue'),
        meta: {
          requiredFeatureFlags: ['enable-supplier-engagement'],
          category: 'Engage Suppliers',
        },
        beforeEnter: [hasPermissions(ACTION.VIEW_LIST_OF_ALL_SUPPLIERS), checkForUserRole, checkForEnabledFeatures],
        redirect: { name: 'suppliersTab' },
        children: [
          {
            path: '',
            name: 'suppliersTab',
            component: () => import('@pages/suppliers/suppliersTabs/SuppliersTab.vue'),
          },
          {
            path: 'purchases',
            name: 'purchasesTab',
            component: () => import('@pages/suppliers/purchasesTab/PurchasesTab.vue'),
          },
          {
            path: 'cohorts',
            name: 'cohortsTab',
            component: () => import('@pages/suppliers/cohortsTabs/CohortsTab.vue'),
            meta: {
              requiredFeatureFlags: ['rollout-supplier-cohorts-v1'],
            },
          },
        ],
      },
      {
        path: 'franchise-engagement',
        name: 'franchiseEngagement',
        component: () => import('@pages/FranchiseEngagement/FranchiseEngagement.vue'),
        meta: {
          requiredFeatureFlags: ['enable-franchise-engagement'],
          category: 'Engage Suppliers',
        },
        beforeEnter: [hasPermissions([ACTION.VIEW_FRANCHISES]), checkForUserRole, checkForEnabledFeatures],
      },
      {
        path: 'suppliers/:supplierId',
        name: 'supplierDetailsPage',
        component: () => import('@pages/suppliers/SupplierDetailsPage.vue'),
        beforeEnter: [hasPermissions(ACTION.VIEW_LIST_OF_ALL_SUPPLIERS), checkForUserRole, checkForEnabledFeatures],
        children: [
          {
            path: '',
            name: 'supplierPurchasesTab',
            component: () => import('@/imports/ui/pages/suppliers/supplierDetailsTabs/OverviewTab.vue'),
          },
          {
            path: 'company-details',
            name: 'companyDetailsTab',
            component: () => import('@/imports/ui/pages/suppliers/supplierDetailsTabs/SupplierCompanyDetailsTab.vue'),
          },
        ],
      },
      {
        path: 'targets',
        name: 'Targets v2',
        component: () => import('@pages/targets/Targets.root.vue'),
        beforeEnter: [hasPermissions(ACTION.VIEW_LIVE_SUSTAINABILITY_TARGETS), checkForUserRole],
        meta: {
          requiredFeatureFlags: ['rollout-targets-v-2'],
        },
        children: [
          {
            path: '',
            name: 'View sustainability targets',
            component: () => import('@pages/targets/TargetsOverviewContainer.vue'),
            meta: {
              pageTitle: 'Sustainability targets',
            },
            redirect: { name: 'Targets overview' },
            children: [
              {
                path: '',
                name: 'Targets overview',
                component: () => import('@pages/targets/TargetsOverview.vue'),
              },
            ],
          },
          {
            path: 'create',
            name: 'Create sustainability target',
            component: () => import('@pages/targets/CreateTarget.vue'),
            meta: {
              layout: 'EmptyLayout',
            },
            beforeEnter: [hasPermissions(ACTION.UPDATE_SUSTAINABILITY_TARGETS)],
          },
          {
            path: ':id',
            name: 'targetPage',
            component: () => import('@pages/targets/Target.vue'),
            beforeEnter: [
              hasPermissions([ACTION.VIEW_LIVE_SUSTAINABILITY_TARGETS, ACTION.VIEW_DRAFT_SUSTAINABILITY_TARGETS]),
              updateActiveTarget,
            ],
          },
          {
            path: ':id/edit',
            name: 'Edit sustainability target',
            component: () => import('@pages/targets/CreateTarget.vue'),
            meta: {
              layout: 'EmptyLayout',
            },
            beforeEnter: [hasPermissions(ACTION.UPDATE_SUSTAINABILITY_TARGETS), updateActiveTarget],
          },
        ],
      },
      {
        path: 'share',
        name: 'share',
        component: () => import('@pages/share/Share.vue'),
        beforeEnter: [hasPermissions([ACTION.VIEW_REPORTS, ACTION.DOWNLOAD_REPORTS]), checkForUserRole],
        meta: {
          disableBUSelectors: true,
          pageTitle: 'Corporate Reports',
          category: 'Reports',
        },
      },
      {
        path: 'organizations',
        name: 'organizations',
        meta: {
          disableBUSelectors: true,
          pageTitle: 'Organizations',
        },
        component: () => import('@pages/admin/Organizations.versionSwitch.vue'),
        beforeEnter: [checkForUserRole],
      },
      {
        path: 'full-impact-breakdown/:page?',
        name: 'fullImpactBreakdown',
        component: () => import('@pages/measure/FullImpactBreakdown.vue'),
        beforeEnter: [
          hasPermissions([
            ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
            ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
          ]),
        ],
        meta: {
          category: 'Analyse',
        },
      },
      {
        path: 'settings/user-organization',
        name: 'userOrganizationSettings',
        component: () => {
          if (FeatureFlagService.isEnabled('enable-user-notifications')) {
            return import('@pages/admin/UserProfile/UserProfile.vue')
          }
          return import('@pages/admin/UserAccountSettings.deprecated.vue')
        },
        beforeEnter: [hasPermissions([ACTION.UPDATE_USER_PROFILE, ACTION.UPDATE_USER_SETTINGS]), checkForUserRole],
        meta: {
          disableBUSelectors: true,
          category: 'Settings',
        },
        children: [
          {
            path: '#account',
            name: 'account',
            component: () => {
              return import('@/imports/ui/pages/admin/UserProfile/UserAccountTab.vue')
            },
            beforeEnter: [
              hasPermissions([ACTION.UPDATE_USER_PROFILE, ACTION.UPDATE_USER_SETTINGS]),
              checkForUserRole,
              checkForAllEnabledFeatures,
            ],
            meta: {
              disableBUSelectors: true,
              category: 'Settings',
              requiredFeatureFlags: ['enable-user-notifications'],
            },
          },
          {
            path: '#notifications',
            name: 'notifications',
            component: () => {
              return import('@/imports/ui/pages/admin/UserProfile/UserNotificationTab.vue')
            },
            beforeEnter: [
              hasPermissions([ACTION.UPDATE_USER_PROFILE, ACTION.UPDATE_USER_SETTINGS]),
              checkForUserRole,
              checkForAllEnabledFeatures,
            ],
            meta: {
              disableBUSelectors: true,
              category: 'Settings',
              requiredFeatureFlags: ['enable-user-notifications'],
            },
          },
        ],
      },
      {
        path: '2fa/setup',
        name: 'twoFactorSetup',
        meta: { layout: 'split-view', dontUseAsIntendedPath: true },
        beforeEnter: [checkForUserRole],
        component: () => import('@pages/user/2fa/TwoFactorSetup.vue'),
      },
      {
        path: '2fa/login',
        name: 'twoFactorLogin',
        meta: { layout: 'split-view', dontUseAsIntendedPath: true },
        beforeEnter: [checkForUserRole],
        component: () => import('@pages/user/2fa/TwoFactorLogin.vue'),
      },
      {
        path: '2fa/login-with-backup-code',
        name: 'twoFactorLoginWithBackupCode',
        meta: { layout: 'split-view', dontUseAsIntendedPath: true },
        beforeEnter: [checkForUserRole],
        component: () => import('@pages/user/2fa/TwoFactorLoginWithBackupCode.vue'),
      },
      {
        path: 'customers',
        name: 'customers',
        meta: {
          requiredFeatureFlags: ['enable_view_customers'],
          pageTitle: 'Customers',
          category: 'Reports',
        },
        beforeEnter: [hasPermissions([ACTION.VIEW_REPORTS, ACTION.DOWNLOAD_REPORTS]), checkForEnabledFeatures],
        component: () => import('@/imports/ui/pages/customers/reports/Customers.vue'),
      },
      {
        path: 'customers/:id',
        name: 'viewCustomer',
        meta: {
          requiredFeatureFlags: ['enable_view_customers'],
          pageTitle: 'Customer',
          category: 'Reports',
        },
        beforeEnter: [hasPermissions([ACTION.VIEW_REPORTS, ACTION.DOWNLOAD_REPORTS]), checkForEnabledFeatures],
        component: () => import('@pages/customers/customer/Customer.vue'),
      },

      {
        path: 'customers-engagement',
        name: 'customersEngagement',
        meta: {
          requiredFeatureFlags: ['enable_customers_engagement_page'],
          category: 'Engage Customers',
        },
        component: () => import('@pages/customers/Customers.vue'),
        redirect: { name: 'customersTab' },
        children: [
          {
            path: '',
            name: 'customersTab',
            component: () => import('@pages/customers/customersTab/CustomersTab.vue'),
          },
          {
            path: 'data-requests',
            name: 'dataRequestsTab',
            component: () => import('@pages/customers/dataRequestsTab/DataRequestsTab.vue'),
          },
        ],
        beforeEnter: [checkForUserRole, checkForEnabledFeatures],
      },

      {
        path: 'corporate-initiatives',
        name: 'corporateInitiativesPage',
        meta: {
          requiredFeatureFlags: ['rollout-corporate-initiatives'],
          pageTitle: 'Corporate initiatives',
          category: 'Reduce Impact',
        },
        component: () => import('@pages/corporateInitiatives/CorporateInitiativesPage.vue'),
        beforeEnter: [hasPermissions([ACTION.VIEW_CORPORATE_INITIATIVES]), checkForUserRole, checkForEnabledFeatures],
      },

      {
        path: 'corporate-initiatives/:id',
        name: 'corporateInitiativePage',
        meta: {
          requiredFeatureFlags: ['rollout-corporate-initiatives'],
          pageTitle: 'Corporate initiative',
          category: 'Reduce Impact',
        },
        component: () => import('@pages/corporateInitiatives/CorporateInitiativePage.vue'),
        beforeEnter: [hasPermissions([ACTION.VIEW_CORPORATE_INITIATIVES]), checkForUserRole, checkForEnabledFeatures],
      },

      {
        path: 'product-initiatives/create',
        name: 'Create Initiative',
        meta: {
          layout: 'no-sidebar',
          pageTitle: 'Create new initiative',
          category: 'Reduce Impact',
          showBetaHeader: true,
        },
        component: () => import('@pages/initiatives/CreateInitiative.vue'),
        beforeEnter: [
          checkForUserRole,
          hasPermissions([ACTION.CREATE_INITIATIVES]),
          checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
        ],
      },
      {
        path: 'product-initiatives/:id',
        name: 'Initiative Detail',
        meta: {
          pageTitle: 'Initiative',
          category: 'Reduce Impact',
          showBetaHeader: true,
        },
        component: () => import('@pages/initiatives/Initiative.vue'),
        beforeEnter: [
          checkForUserRole,
          hasPermissions([ACTION.VIEW_INITIATIVES]),
          checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
        ],
      },
      {
        path: 'product-initiatives',
        name: 'Initiatives List',
        meta: {
          pageTitle: 'Initiatives',
          category: 'Reduce Impact',
          showBetaHeader: true,
        },
        component: () => import('@pages/initiatives/Initiatives.vue'),
        beforeEnter: [
          checkForUserRole,
          hasPermissions([ACTION.VIEW_INITIATIVES]),
          checkForMultipleOrgModuleEnabled([
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER,
          ]),
        ],
      },

      // /**
      //  * Product modelling
      //  */
      {
        path: 'product-modelling',
        name: 'productModelling',
        meta: {
          pageTitle: 'Product Initiatives',
          category: 'Reduce Impact',
          featureFlagForRoute: {
            flag: 'rollout-product-modelling',
            name: 'Initiatives List',
          },
          showBetaHeader: true,
        },
        component: () => import('@pages/ProductModelling/ProductInitiatives.vue'),
        beforeEnter: [
          checkForUserRole,
          hasPermissions([ACTION.VIEW_INITIATIVES]),
          checkForMultipleOrgModuleEnabled([
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER,
          ]),
          checkForFeatureFlagExpectedRoute,
        ],
      },
      {
        path: 'product-modelling/new',
        name: 'createInitiative',
        meta: {
          layout: 'empty',
          pageTitle: 'Create Initiative',
          category: 'Reduce Impact',
          featureFlagForRoute: {
            flag: 'rollout-product-modelling',
            name: 'Initiatives List',
          },
        },
        component: () => import('@pages/ProductModelling/CreateInitiative.vue'),
        beforeEnter: [
          checkForUserRole,
          hasPermissions([ACTION.VIEW_INITIATIVES]),
          checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
          checkForFeatureFlagExpectedRoute,
        ],
      },
      {
        path: 'product-modelling/:initiativeId',
        name: 'viewInitiative',
        meta: {
          pageTitle: 'Initiative',
          category: 'Reduce Impact',
          featureFlagForRoute: {
            flag: 'rollout-product-modelling',
            name: 'Initiatives List',
          },
          showBetaHeader: true,
        },
        component: () => import('@pages/ProductModelling/ViewInitiative.vue'),
        beforeEnter: [
          checkForUserRole,
          hasPermissions([ACTION.VIEW_INITIATIVES]),
          checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
          checkForFeatureFlagExpectedRoute,
        ],
      },

      // /**
      //  * Stand alone pcf module
      //  */

      // /**
      //  * Basic Facility
      //  */
      {
        path: 'processing-data',
        name: 'processingData',
        component: () => import('@pages/basicFacility/FacilityData.vue'),
        beforeEnter: [checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER)],
        meta: {
          disableBUSelectors: true,
          category: 'Manage Data',
        },
      },
      {
        path: 'processing-data/:facilityId',
        name: 'basicFacilityPage',
        component: () => import('@pages/basicFacility/FacilityDetails.vue'),
        beforeEnter: [checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER)],
        redirect: { name: 'basicFacilityDetailsTab' },
        meta: {
          disableBUSelectors: true,
          category: 'Manage Data',
        },
        children: [
          {
            name: 'basicFacilityDetailsTab',
            path: '',
            component: () => import('@components/basicFacility/facilityDetails/FacilityDetails.vue'),
          },
          {
            name: 'processingDataTab',
            path: '',
            component: () => import('@pages/basicFacility/ProcessingData.vue'),
          },
        ],
      },

      {
        path: 'suppliers/product-carbon-footprint',
        name: 'supplierProductCarbonFootprint',
        meta: {
          requiredFeatureFlags: ['rollout-standalone-pcf-37955'],
          pageTitle: 'Product Footprint',
          category: 'Manage Data',
        },
        redirect: { name: 'pcfListTab' },
        component: () =>
          import('@/imports/ui/pages/supplierProducts/productCarbonFootprint/SupplierProductCarbonFootprintPage.vue'),
        beforeEnter: [
          checkForEnabledFeatures,
          checkForMultipleOrgModuleEnabled([
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER,
          ]),
        ],
        children: [
          {
            name: 'pcfListTab',
            path: '',
            component: () =>
              import('@pages/supplierProducts/productCarbonFootprint/SupplierProductCarbonFootprint.vue'),
          },
          {
            name: 'createPcfGuideTab',
            path: '',
            component: () => import('@pages/supplierProducts/productCarbonFootprint/CreatePcfGuide.vue'),
          },
        ],
      },

      // /**
      //  * Admin routes
      //  */

      {
        path: 'organization',
        name: 'editRootOrganization',
        component: () => import('@pages/editOrganization/EditOrganization.vue'),
        meta: {
          layout: 'no-sidebar',
          category: 'Settings',
        },
        beforeEnter: [checkIsActiveOrgRootOrg],
        children: [
          {
            name: 'editContractScope',
            path: '',
            meta: {
              pageTitle: 'Settings - Contract Scope',
            },
            beforeEnter: [
              checkIsActiveOrgRootOrg,
              // TODO: RBAC enable when SOLUTION_ADVISOR role is enabled, and remove checkForSuperuserRole
              // hasPermissions([ACTION.VIEW_CONTRACT_SCOPE_SETTINGS, ACTION.UPDATE_CONTRACT_SCOPE_SETTINGS]),
              checkForSuperuserRole,
            ],
            component: () => import('@pages/editOrganization/EditContractScope.vue'),
          },
          {
            name: 'editDetailsAndSettings',
            path: 'details-and-settings',
            beforeEnter: [checkIsActiveOrgRootOrg, hasPermissions([ACTION.UPDATE_ORGANISATION_DETAILS])],
            meta: {
              pageTitle: 'Settings - Details & Settings',
            },
            component: () => import('@pages/editOrganization/EditDetailsAndSettings.vue'),
          },
          {
            name: 'editAuthenticationType',
            path: 'authentication',
            beforeEnter: [
              checkIsActiveOrgRootOrg,
              hasPermissions([
                ACTION.UPDATE_AUTHENTICATION_SETTINGS,
                ACTION.CREATE_INTEGRATIONS,
                ACTION.UPDATE_INTEGRATIONS,
                ACTION.DELETE_INTEGRATIONS,
              ]),
            ],
            meta: {
              pageTitle: 'Settings - Authentication',
            },
            component: () => import('@pages/editOrganization/EditAuthentication.vue'),
          },
        ],
      },
      {
        path: 'manage/users',
        name: 'manageUsers',
        beforeEnter: [hasPermissions([ACTION.CREATE_NEW_USERS])],
        meta: {
          pageTitle: 'Manage Users',
        },
        component: () => import('@pages/editOrganization/EditUsers/EditUsers.vue'),
      },
      {
        path: 'manage/users/:id',
        name: 'manageUser',
        beforeEnter: [hasPermissions([ACTION.UPDATE_USER_ROLES])],
        meta: {
          pageTitle: 'Manage User',
        },
        component: () => import('@pages/admin/ManageUser.vue'),
      },
      {
        path: 'organization/setup',
        name: 'organizationSetup',
        meta: {
          layout: 'no-sidebar',
          category: 'Settings',
        },
        component: () => import('@pages/admin/CreateOrganization.vue'),
        beforeEnter: [checkForAdminRole],
      },
      {
        path: 'supplier/settings',
        name: 'supplierSettings',
        meta: {
          requiredFeatureFlags: ['allow_edit_organization'],
          category: 'Settings',
        },
        component: () => import('@pages/admin/EditSupplierOrganization.vue'),
        beforeEnter: checkForEnabledFeatures,
      },
      {
        path: 'organization/settings/:page?',
        name: 'organizationSettings',
        meta: {
          requiredFeatureFlags: ['allow_edit_organization'],
          disableBUSelectors: true,
          category: 'Settings',
        },
        component: () => import('@pages/admin/CreateOrganization.vue'),
        beforeEnter: checkForEnabledFeatures,
      },
      {
        path: 'organizations/create',
        name: 'createOrganization',
        meta: {
          layout: 'no-header',
          requiredFeatureFlags: ['allow_create_organization'],
          category: 'Settings',
        },
        component: () => import('@pages/admin/CreateOrganization.vue'),
        beforeEnter: checkForEnabledFeatures,
      },

      {
        path: 'organizations/edit/:page/:id',
        name: 'editOrganization',
        meta: {
          layout: 'no-header',
          requiredFeatureFlags: ['allow_edit_other_organization'],
          category: 'Settings',
        },
        component: () => import('@pages/admin/CreateOrganization.vue'),
        beforeEnter: checkForEnabledFeatures,
      },

      // data sources
      {
        path: 'corporate-data',
        name: 'corporateData',
        component: () => import('@pages/dataSources/DataSourcesPage.vue'),
        beforeEnter: [checkForUserRole],
        meta: {
          disableYearSelector: true,
          pageTitle: 'Corporate Data',
          category: 'Manage Data',
        },
      },
      {
        path: 'corporate-data/:id/:uploadId?',
        name: 'corporateDataSource',
        component: () => import('@pages/dataSources/DataSourcePage.vue'),
        beforeEnter: [checkForUserRole],
        meta: {
          requiredFeatureFlags: ['allow_access_data_sources'],
          disableBUSelectors: true,
          category: 'Manage Data',
        },
      },
      {
        path: 'corporate-data/:id/:uploadId?/v2',
        name: 'corporateDataSourceV2',
        component: () => import('@pages/dataSources/DataSourcePage.v2.vue'),
        beforeEnter: [checkForUserRole],
        meta: {
          requiredFeatureFlags: ['allow_access_data_sources'],
          disableBUSelectors: true,
          category: 'Manage Data',
        },
      },

      // redirects from old data-sources link to the updates /corporate-data link
      {
        path: 'data-sources',
        name: 'dataSources',
        redirect: { name: 'corporateData' },
      },
      {
        path: 'data-source/:id/:uploadId?',
        name: 'dataSource',
        redirect: { name: 'corporateDataSource' },
      },

      /**
       * Organization Structure
       */
      {
        path: 'organization-structure',
        name: 'organization-structure',
        component: () => import('@pages/organizationStructure/OrganizationStructure.vue'),
        beforeEnter: [hasPermissions(ACTION.VIEW_THE_LIST_OF_BUSINESS_UNITS_IN_THE_ORGANISATION)],
        meta: {
          disableBUSelectors: true,
          category: 'Manage Data',
        },
      },

      {
        path: 'organization-structure/business-unit/edit/:orgId?',
        name: 'editOrgStructureBusinessUnit',
        component: () => import('@pages/organizationStructure/OrgStrucBusinessUnit.vue'),
        beforeEnter: [
          hasPermissions([
            ACTION.UPDATE_THE_DETAILS_OF_A_BUSINESS_UNIT,
            ACTION.UPDATE_USER_ROLES_WITHIN_A_BUSINESS_UNIT,
          ]),
        ],
        meta: {
          disableBUSelectors: true,
          category: 'Manage Data',
        },
      },

      {
        path: 'organization-structure/business-unit/add/:orgId?',
        name: 'addOrgStructureBusinessUnit',
        component: () => import('@pages/organizationStructure/OrgStrucBusinessUnit.vue'),
        beforeEnter: [hasPermissions([ACTION.CREATE_A_NEW_BUSINESS_UNIT])],
        meta: {
          disableBUSelectors: true,
          category: 'Manage Data',
        },
      },

      {
        path: 'organization-structure/facilities/:orgId/:facilityId',
        name: 'facilityV2',
        component: () => import('@pages/facilities/Facility.vue'),
        beforeEnter: [hasPermissions([ACTION.CREATE_FACILITIES, ACTION.UPDATE_FACILITIES])],
        meta: {
          disableBUSelectors: true,
          category: 'Manage Data',
        },
      },

      {
        path: 'organization-structure/facilities',
        name: 'facilities',
        component: () => import('@pages/facilities/Facilities.vue'),
        meta: {
          disableBUSelectors: true,
          category: 'Manage Data',
        },
      },

      {
        path: 'organization-structure/facilities/:action/:orgId/:facilityId?/:facName?',
        name: 'organizationFacilityV2',
        component: () => import('@pages/facilities/Facility.vue'),
        beforeEnter: [hasPermissions([ACTION.CREATE_FACILITIES, ACTION.UPDATE_FACILITIES])],
        meta: {
          hideBUSelectors: true,
          category: 'Manage Data',
        },
      },

      /**
       * Business Views
       */
      {
        path: 'business-unit/not-operational/:name/:year',
        name: 'businessUnitNotOperational',
        component: () => import('@pages/businessUnitViews/BusinessUnitNotOperational.vue'),
        beforeEnter: [checkForUserRole],
      },

      /**
       * Internal AQ Views
       */
      {
        path: 'aq',
        name: 'AQWelcome',
        component: () => import('@pages/aq/Welcome.vue'),
        beforeEnter: [hasPermissions(ACTION.ACCESS_ALTRUISTIQ_ADVISOR), checkForAdvisorRole],
        meta: {
          layout: 'empty',
          category: 'AQ Advisor',
        },
      },
      {
        path: 'aq',
        name: 'AdvisorApp',
        component: () => import('@pages/aq/AdvisorApp.vue'),
        beforeEnter: [hasPermissions(ACTION.ACCESS_ALTRUISTIQ_ADVISOR), checkForAdvisorRole],
        meta: {
          layout: 'empty',
          pageTitle: 'Advisor App',
          category: 'AQ Advisor',
        },
        children: [
          {
            path: 'ef-catalogue',
            name: 'EFCatalogue',
            component: () => import('@pages/aq/EfCatalogue.vue'),
            meta: {
              requiredFeatureFlags: ['rollout-upload-emission-factors'],
            },
            beforeEnter: [checkForEnabledFeatures],
            children: [
              {
                path: 'ef-catalogue/calculation-methods',
                name: 'CalculationMethods',
                component: () => import('@pages/aq/efcatalogue/CalculationMethod.vue'),
                children: [
                  {
                    path: 'activity/:activityCode',
                    name: 'CalculationMethodActivity',
                    component: () => import('@pages/aq/efcatalogue/CalculationMethod.vue'),
                  },
                ],
              },
              {
                path: 'ef-catalogue/emission-factors',
                name: 'EmissionFactors',
                component: () => import('@pages/aq/efcatalogue/EmissionFactors.vue'),
              },
            ],
          },
          {
            path: 'tagging/:query?',
            name: 'Tagging',
            component: () => import('@pages/aq/Tagging/Tagging.vue'),
          },
          {
            path: 'your-calculations',
            name: 'Calculations',
            component: () => import('@pages/aq/Calculations/Calculations.vue'),
          },
          {
            path: 'pdf-ingestion',
            name: 'PDFIngestion',
            component: () => import('@pages/aq/PDFIngestion/PDFIngestion.vue'),
            beforeEnter: (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
              const lsScript = document.createElement('script')
              lsScript.src = 'https://cdn.jsdelivr.net/npm/@heartexlabs/label-studio@1.4.0/build/static/js/main.min.js'

              const lsStyles = document.createElement('link')
              lsStyles.rel = 'stylesheet'
              lsStyles.type = 'text/css'
              lsStyles.href = 'https://cdn.jsdelivr.net/npm/@heartexlabs/label-studio@1.4.0/build/static/css/main.css'

              document.head.appendChild(lsStyles)
              document.body.appendChild(lsScript)

              next()
            },
            children: [
              {
                path: 'labelling/:orgId?/:buId?/:dataSourceId?/:projectId?/:taskId?',
                name: 'PDFLabelling',
                component: () => import('@pages/aq/PDFLabelling/PDFLabelling.vue'),
              },
              {
                path: 'review-annotations/:orgId/:dataSourceId/:projectId/:dataSourceName?',
                name: 'OCROutput',
                component: () => import('@pages/aq/OCROutput/OCROutput.vue'),
              },
            ],
          },
          {
            path: 'pdf-ingestion/page-grouping/:query?',
            name: 'PDFPageGrouping',
            component: () => import('@pages/aq/PDFPageGrouping/PDFPageGrouping.vue'),
            meta: {
              requiredFeatureFlags: ['rollout-pdf-v2'],
            },
          },
          {
            path: 'pdf-ingestion',
            component: () => import('@pages/aq/PDFIngestion/PDFIngestion.vue'),
            beforeEnter: (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
              const lsScript = document.createElement('script')
              lsScript.src = 'https://cdn.jsdelivr.net/npm/@heartexlabs/label-studio@1.4.0/build/static/js/main.min.js'
              lsScript.onload = () => {
                // Script loaded, continue to the next step in the navigation
                next()
              }

              const lsStyles = document.createElement('link')
              lsStyles.rel = 'stylesheet'
              lsStyles.type = 'text/css'
              lsStyles.href = 'https://cdn.jsdelivr.net/npm/@heartexlabs/label-studio@1.4.0/build/static/css/main.css'

              document.head.appendChild(lsStyles)
              document.body.appendChild(lsScript)
            },
            meta: {
              requiredFeatureFlags: ['rollout-pdf-v2'],
            },
            children: [
              {
                path: 'page-annotation/:orgId/:dataSourceId/:query?',
                name: 'PDFPageAnnotation',
                component: () => import('@pages/aq/PDFPageAnnotation/PDFPageAnnotation.vue'),
              },
              {
                path: 'pdf-review/:orgId/:dataSourceId',
                name: 'PDFReview',
                component: () => import('@pages/aq/PDFReviewPageV2/PDFReviewPage.v2.vue'),
              },
            ],
          },
        ],
      },

      /**
       * Products
       *
       * TODO: Remove isDev flag before releasing to production
       */
      {
        path: 'products',
        name: 'products',
        component: () => {
          // Legacy Products page (Gousto)
          if (FeatureFlagService.isEnabled('enable_product_footprint_page')) {
            return import('@/imports/ui/pages/products/Products.deprecated.vue')
          }

          // Everyone other than Gousto
          return import('@/imports/ui/pages/products/Products.vue')
        },
        beforeEnter: [
          hasPermissions([
            ACTION.VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
            ACTION.DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
          ]),
          checkForUserRole,
          checkForMultipleOrgModuleEnabled([
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER,
          ]),
        ],
        meta: {
          pageTitle: 'Product Footprints',
          category: 'Product Footprinting',
        },
      },

      /**
       * Corporate footprints
       * */
      {
        path: 'progress',
        component: () => import('@pages/corporateFootprints/FootprintsRoute.vue'),
        beforeEnter: [checkForUserRole, checkForEnabledFeatures, checkIsActiveOrgRootOrg],
        meta: {
          category: 'Manage Data',
          showBetaHeader: true,
        },
        children: [
          {
            path: '',
            name: 'progress',
            component: () => import('@pages/corporateFootprints/FootprintsPage/FootprintsPage.vue'),
          },
          {
            path: ':year',
            name: 'progressPerYear',
            component: () => import('@/imports/ui/pages/corporateFootprints/FootprintPage/FootprintPage.vue'),
            beforeEnter: [checkForUserRole, checkForEnabledFeatures],
          },
          {
            path: ':year/:subActivityId',
            component: () => import('@pages/corporateFootprints/AssociationsPage.vue'),
            beforeEnter: [checkForUserRole, checkForEnabledFeatures],
            children: [
              {
                path: '',
                name: 'progressPerYearAndActivity',
                component: () => import('@pages/corporateFootprints/FacilitiesTab/FacilitiesTab.vue'),
              },
              {
                path: 'datasources',
                name: 'activityProgressDatasources',
                component: () => import('@pages/corporateFootprints/DatasourcesTab.vue'),
              },
            ],
          },
          {
            path: ':year/scope',
            name: 'progressScopes',
            component: () => import('@pages/corporateFootprints/ScopePage.vue'),
            beforeEnter: [checkForAdminRole, checkForEnabledFeatures],
          },
        ],
      },
      {
        path: 'progress/:year/franchises',
        name: 'franchiseProgressPage',
        component: () =>
          import('@pages/corporateFootprints/FranchiseFootprintsDetailsPage/FranchiseFootprintsDetailsPage.vue'),
        beforeEnter: [checkForAdminRole, checkForEnabledFeatures],
        meta: {
          requiredFeatureFlags: ['rollout-franchise-engagement-v2'],
        },
      },
      {
        path: 'progress/:year/franchise-estimation-wizard',
        name: 'franchiseEstimationWizard',
        beforeEnter: [checkForAdminRole, checkForEnabledFeatures],
        component: () => import('@pages/FranchiseEngagement/EstimationsWizard/EstimationWizard.vue'),
        meta: {
          requiredFeatureFlags: ['rollout-franchise-engagement-v2'],
          layout: 'EmptyLayout',
        },
      },
      /**
       * Product footprint
       *
       * TODO: Remove isDev flag before releasing to production
       */
      {
        path: 'products/:id',
        name: 'productFootprint',
        component: () => import('@pages/products/ProductFootprint.versionToggle.vue'),
        beforeEnter: [
          hasPermissions([
            ACTION.VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
            ACTION.DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
          ]),
          checkForUserRole,
          checkForMultipleOrgModuleEnabled([
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER,
          ]),
        ],
        meta: {
          pageTitle: 'Product Footprint',
          category: 'Product Footprinting',
        },
        children: [
          {
            path: 'overview',
            name: 'productOverviewTab',
            component: () => import('@pages/products/ProductFootprint/ProductFootprintOverviewTab.vue'),
          },
          {
            path: 'snapshots-tab',
            name: 'snapshotsTab',
            component: () => import('@pages/products/ProductFootprint/ProductSnapshotTab.vue'),
          },
        ],
      },
      {
        path: 'products/upload',
        name: 'ProductsUpload',
        component: () => import('@pages/products/ProductDataSources/ProductDataSources.vue'),
        beforeEnter: [
          checkForUserRole,
          checkForEnabledFeatures,
          checkForMultipleOrgModuleEnabled([
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER,
          ]),
        ],
        meta: {
          pageTitle: 'Upload products',
          category: 'Manage Data',
        },
      },
      {
        path: 'products/list',
        name: 'ProductsUploadList',
        beforeEnter: [
          checkForUserRole,
          checkForMultipleOrgModuleEnabled([
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER,
          ]),
        ],
        component: () => import('@pages/products/ProductList/ProductList.vue'),
        meta: {
          pageTitle: 'Upload products',
          category: 'Manage Data',
        },
      },
      {
        path: 'products/upload/:id',
        name: 'ProductsUploadProductId',
        beforeEnter: [
          checkForUserRole,
          checkForMultipleOrgModuleEnabled([
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER,
          ]),
        ],
        component: () => import('@pages/products/ProductDataSourcePage/ProductDatasource.hoc.vue'),
        meta: {
          pageTitle: 'Upload products',
          category: 'Manage Data',
        },
      },
      {
        path: 'products/total-product-quantity',
        name: 'TotalProductQuantity',
        beforeEnter: [
          checkForUserRole,
          checkForMultipleOrgModuleEnabled([
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER,
            ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_BUSINESS_ENTERPRISE_TIER,
          ]),
        ],
        component: () => import('@pages/products/totalProductQuantity/TotalProductQuantities.vue'),
        meta: {
          pageTitle: 'Total Product Quantity',
          category: 'Manage Data',
        },
      },

      /**
       * Metabase custom dashboards
       */
      {
        path: 'analytics/:path*',
        name: 'MainAnalyticsCollection',
        component: () => import('@pages/customDashboards/MainDashboard.vue'),
        beforeEnter: [hasPermissions([ACTION.VIEW_CUSTOM_DASHBOARDS]), checkForUserRole],
        meta: {
          layout: 'default-no-padding',
          category: 'Explore Data',
        },
      },
      {
        path: 'analytics/login',
        name: 'MetabaseSSOLogin',
        component: () => import('@pages/customDashboards/MetabaseSSOLogin.vue'),
        beforeEnter: [hasPermissions([ACTION.VIEW_CUSTOM_DASHBOARDS]), checkForUserRole, checkForEnabledFeatures],
        meta: {
          layout: 'no-sidebar-or-navbar',
          requiredFeatureFlags: ['enable_metabase_dashboards'],
        },
      },

      /**
       * Legal entities
       * */

      {
        path: 'legal-entities',
        name: 'legalEntities',
        component: () => import('@pages/legalEntities/LegalEntitiesPage.vue'),
        beforeEnter: [checkForUserRole, checkForEnabledFeatures, checkIfNotSupplier],
        meta: {
          requiredFeatureFlags: ['enable_legal_entities'],
          category: 'Manage Data',
        },
      },

      /**
       * Material and packaging
       *
       */
      {
        path: 'materials-and-packaging',
        name: 'materialsAndPackaging',
        component: () => import('@pages/materialsAndPackaging/MaterialsAndPackaging.vue'),
        beforeEnter: [checkForUserRole, checkForEnabledFeatures],
        meta: {
          requiredFeatureFlags: ['enable-materials-breakdown-page'],
          category: 'Product Footprinting',
        },
      },

      {
        path: 'materials-and-packaging/:id',
        name: 'materialsAndPackagingDetails',
        component: () => import('@pages/materialsAndPackaging/MaterialsAndPackagingDetailsPage.vue'),
        beforeEnter: [checkForUserRole, checkForEnabledFeatures],
        meta: {
          requiredFeatureFlags: ['enable-materials-breakdown-page'],
          category: 'Product Footprinting',
        },
      },

      /**
       * Facility footprinting
       */
      {
        path: 'facility-footprints',
        name: 'facilityFootprints',
        component: () => import('@pages/facilityFootprint/FacilityFootprintOverview/FacilityFootprintOverview.vue'),
        beforeEnter: [
          hasPermissions([
            ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
            ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
          ]),
          checkForUserRole,
        ],
        meta: {
          pageTitle: 'Facility Footprints',
          category: 'Analyse',
        },
      },

      {
        path: 'facility-footprints/:facilityId',
        name: 'FacilityFootprint',
        component: () => import('@pages/facilityFootprint/FacilityFootprintDetail/FacilityFootprintDetail.vue'),
        beforeEnter: [
          hasPermissions([
            ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
            ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
          ]),
          checkForUserRole,
          checkForEnabledFeatures,
        ],
        meta: {
          pageTitle: 'Facility Footprint',
          category: 'Analyse',
        },
      },
      {
        path: 'data-request/:id',
        name: 'dataRequestResponse',
        component: () => import('@pages/dataRequestResponse/DataRequestResponsePage.vue'),
        meta: {
          requiredFeatureFlags: ['enable_supplier_survey'],
          layout: 'empty',
          category: 'Engage Suppliers',
        },
        beforeEnter: [checkForUserRole, checkForEnabledFeatures],
      },

      {
        path: 'basic-calculator',
        name: 'basicCalculator',
        component: () => import('@pages/basicCalculator/index.vue'),
        meta: {
          layout: 'empty',
        },
        children: [
          {
            path: 'introduction/:id?',
            name: 'basicCalculatorIntroduction',
            component: () =>
              import('@components/BasicFootprints/BasicCalculatorWizard/Introduction/CalculatorIntroduction.vue'),
            meta: {
              layout: 'empty',
            },
            beforeEnter: [checkForUserRole, checkForEnabledFeatures],
          },

          {
            path: 'calculation/:id',
            name: 'basicCalculation',
            meta: {
              layout: 'empty',
              category: 'Manage Data',
            },
            component: () => {
              if (!FeatureFlagService.isEnabled('rollout-standalone-pcf-37955')) {
                return import('@pages/basicCalculator/BasicFootprintCalculator.deprecated.vue')
              }

              return import('@pages/basicCalculator/BasicFootprintCalculator.vue')
            },
            beforeEnter: [checkForUserRole, checkForEnabledFeatures],
          },
        ],
      },
      {
        path: 'product-basic-calculator',
        name: 'productBasicCalculator',
        meta: { layout: 'empty', category: 'Manage Data' },
        beforeEnter: [checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER)],
        children: [
          {
            path: 'introduction',
            name: 'productBasicIntroduction',
            component: () => {
              return import('@pages/productBasicCalculator/ProductWizardIntroduction.hoc.vue')
            },
            beforeEnter: [checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER)],
          },
          {
            path: 'calculation/:year',
            name: 'productBasicCalculation',
            component: () => {
              return import('@pages/productBasicCalculator/ProductBasicFootprintCalculator.vue')
            },
            beforeEnter: [checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT_STARTER_TIER)],
          },
        ],
      },
      {
        path: 'audit-logs',
        name: 'audit-logs',
        component: () => import('@pages/audit-logs/AuditLogs.root.vue'),
        meta: {
          requiredFeatureFlags: ['enable-audit-logs'],
        },
        beforeEnter: [checkForUserRole, checkForEnabledFeatures],
      },
      {
        path: 'no-access',
        name: 'noAccess',
        component: () => import('@/imports/ui/components/rbac/NoAccessToPage.vue'),
        meta: {
          pageTitle: 'No Access',
        },
      },
      {
        path: ':pathMatch(.*)*',
        name: '404',
        component: () => import('@pages/404.vue'),
        beforeEnter: [checkForUserRole, redirectToOrgBasedUrl],
        meta: {
          pageTitle: 'Altruistiq 404',
        },
      },
    ],
    // END ORG SPECIFIC ROUTES
  },
  /**
   * User routes
   */
  {
    path: '/franchise-invitation/:token',
    name: 'acceptFranchiseInviteAdvanced',
    component: () => import('@pages/supplier/AcceptSupplierInviteAdvanced.vue'),
    meta: {
      layout: 'empty',
      public: true,
    },
  },
  // commented out new supplier sign up for now until FF is created for new sign up
  {
    path: '/supplier-invitation-v2/:token',
    name: 'acceptSupplierInviteAdvanced',
    component: () => {
      return import('@pages/supplier/AcceptSupplierInviteAdvanced.vue')
    },
    meta: {
      layout: 'empty',
      public: true,
    },
  },

  {
    path: '/supplier-invitation-v3/:token',
    name: 'acceptSupplierInviteAdvancedV3',
    component: () => {
      return import('@pages/suppliers/acceptInvite/AcceptSupplierInviteAdvanced.vue')
    },
    meta: {
      layout: 'empty',
      public: true,
    },
  },

  {
    path: '/request-access',
    name: 'request-access',
    component: () => import('@pages/RequestAccess.vue'),
    beforeEnter: [],
    meta: { layout: 'no-sidebar-or-navbar', public: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404-unauthorized',
    component: () => import('@pages/404.vue'),
    beforeEnter: [redirectToOrgBasedUrl],
    meta: {
      pageTitle: 'Altruistiq 404',
      public: true,
    },
  },
] as RouteRecordRaw[]
