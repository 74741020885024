import ManageDataIcon from '@components/icons/ManageDataIcon.vue'
import AnalyseIcon from '@components/icons/AnalyseIcon.vue'
import ReduceImpactLogo from '@components/icons/ReduceImpactIcon.vue'
import EngageSuppliersIcon from '@components/icons/EngageSuppliersIcon.vue'
import ReportIcon from '@components/icons/ReportIcon.vue'
import DashboardIcon from '@components/icons/DashboardIcon.vue'

import { ACTION } from '@lib/constants/permission/permissionConstants'

import type { NavGroup } from '@/imports/@types/Navigation'
import { ORGANIZATION_MODULES } from '@/imports/@enums/organizations.enums'

export const getDefaultNav = (): NavGroup[] => [
  {
    label: 'Dashboard',
    icon: DashboardIcon,
    to: {
      name: 'dashboard',
    },
  },
  {
    label: 'Manage Data',
    icon: ManageDataIcon,
    children: [
      {
        label: 'Corporate Footprints',
        to: {
          name: 'progress',
        },
        meta: {
          isBeta: true,
          needsRootOrg: true,
        },
      },

      {
        label: 'Corporate Data',
        to: {
          name: 'corporateData',
        },
      },

      {
        label: 'Product Data',
        to: {
          name: 'ProductsUpload',
        },
        meta: {
          requiredOrgModule: ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
        },
      },

      {
        label: 'Import PCFs',
        to: {
          name: 'supplierProducts',
        },
        featureFlag: 'enable_supplier_product_footprint',
      },

      {
        label: 'Legal Entities',
        to: {
          name: 'legalEntities',
        },
        featureFlag: 'enable_legal_entities',
      },

      {
        label: 'Organisation Structure',
        to: {
          name: 'organization-structure',
        },
      },

      {
        label: 'Facility Structure',
        to: {
          name: 'facilities',
        },
      },

      {
        label: 'Intensity Metrics',
        to: {
          name: 'intensityMetrics',
        },
      },
    ],
  },
  {
    label: 'Analyse',
    icon: AnalyseIcon,
    children: [
      {
        label: 'Overview',
        to: {
          name: 'measure',
        },
      },

      {
        label: 'Facilities',
        to: {
          name: 'facilityFootprints',
        },
      },

      {
        label: 'Products',
        to: {
          name: 'products',
        },
        meta: {
          requiredOrgModule: ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
        },
      },
      {
        label: 'Explore Data',
        to: {
          name: 'MainAnalyticsCollection',
        },
      },
    ],
  },
  {
    label: 'Reduce Impact',
    icon: ReduceImpactLogo,
    children: [
      {
        label: 'Targets',
        to: {
          name: 'View sustainability targets',
        },
        featureFlag: 'rollout-targets-v-2',
      },
      {
        label: 'Corporate Initiatives',
        to: {
          name: 'corporateInitiativesPage',
        },
        featureFlag: 'rollout-corporate-initiatives',
        meta: {
          needsPermissions: [ACTION.VIEW_CORPORATE_INITIATIVES],
        },
      },
      {
        label: 'Product Initiatives',
        to: {
          name: 'productModelling',
        },
        meta: {
          requiredOrgModule: ORGANIZATION_MODULES.PRODUCT_FOOTPRINT,
        },
      },
    ],
  },

  {
    label: 'Reports',
    icon: ReportIcon,
    children: [
      {
        label: 'Corporate Reports',
        to: {
          name: 'share',
        },
      },
      {
        label: 'Customer Reports',
        to: {
          name: 'customers',
        },
        featureFlag: 'enable_view_customers',
      },
    ],
  },

  {
    label: 'Engage Partners',
    icon: EngageSuppliersIcon,
    featureFlag: ['enable-supplier-engagement', 'enable-franchise-engagement'],
    children: [
      {
        label: 'Suppliers',
        to: {
          name: 'suppliersV2Page',
        },
        featureFlag: 'enable-supplier-engagement',
      },

      {
        label: 'Franchises',
        to: {
          name: 'franchiseEngagement',
        },
        featureFlag: 'enable-franchise-engagement',
      },

      {
        label: 'Data Requests',
        to: {
          name: 'supplierDataRequests',
        },
      },
    ],
  },
  {
    label: 'Engage Customers',
    icon: EngageSuppliersIcon,
    featureFlag: 'enable_customers_engagement_page',
    children: [
      {
        label: 'Customers',
        to: {
          name: 'customersEngagement',
        },
      },
    ],
  },
]
