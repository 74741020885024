import { IsUUID, IsString, IsOptional } from 'class-validator'

import { ValidateDTO } from '..'

import { PRODUCT_FOOTPRINTS_CONTROLLER } from './controller.common-vars'

export const PRODUCT_UPDATED_AT_SUBPATH = 'last-updated-at'
export const PRODUCT_UPDATED_AT_URI = `${PRODUCT_FOOTPRINTS_CONTROLLER}/${PRODUCT_UPDATED_AT_SUBPATH}`

export class ProductUpdatedAtDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsString()
  startMonth!: string

  @IsString()
  endMonth!: string

  @IsString()
  @IsOptional()
  productId?: string

  @IsUUID()
  @IsOptional()
  facilityId?: string
}

export type ProductUpdatedAtDTORes = {
  lastUpdated: string
}
