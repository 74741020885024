<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    class="mr-1"
  >
    <path
      d="M9.99979 0.75C8.75916 0.759375 7.53252 1.01304 6.38993 1.49652C5.24734 1.97999 4.21117 2.6838 3.3406 3.56776C2.47004 4.45171 1.78212 5.49849 1.31614 6.64833C0.850155 7.79817 0.615235 9.02853 0.624792 10.2692V12C0.624792 12.3315 0.756488 12.6495 0.990909 12.8839C1.22533 13.1183 1.54327 13.25 1.87479 13.25H18.1248C18.4563 13.25 18.7743 13.1183 19.0087 12.8839C19.2431 12.6495 19.3748 12.3315 19.3748 12V10.2667C19.3934 7.76152 18.4162 5.35156 16.6581 3.56687C14.9 1.78218 12.5049 0.768936 9.99979 0.75Z"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 13.25V12.625C7.5 11.962 7.76339 11.3261 8.23223 10.8572C8.70107 10.3884 9.33696 10.125 10 10.125C10.663 10.125 11.2989 10.3884 11.7678 10.8572C12.2366 11.3261 12.5 11.962 12.5 12.625V13.25"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.875 4.5025L10 10.1275"
      stroke="#80ADAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.0625 9.5C4.00069 9.5 3.94027 9.51833 3.88888 9.55267C3.83749 9.587 3.79744 9.63581 3.77379 9.69291C3.75014 9.75001 3.74395 9.81285 3.756 9.87347C3.76806 9.93409 3.79783 9.98977 3.84153 10.0335C3.88523 10.0772 3.94092 10.1069 4.00153 10.119C4.06215 10.1311 4.12499 10.1249 4.18209 10.1012C4.23919 10.0776 4.288 10.0375 4.32233 9.98612C4.35667 9.93473 4.375 9.87431 4.375 9.8125C4.375 9.72962 4.34208 9.65013 4.28347 9.59153C4.22487 9.53292 4.14538 9.5 4.0625 9.5Z"
      fill="#80ADAF"
      stroke="#80ADAF"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.3125 6.3775C5.25069 6.3775 5.19027 6.39583 5.13888 6.43017C5.08749 6.46451 5.04744 6.51331 5.02379 6.57041C5.00014 6.62751 4.99395 6.69035 5.006 6.75097C5.01806 6.81159 5.04783 6.86727 5.09153 6.91097C5.13523 6.95468 5.19092 6.98444 5.25153 6.9965C5.31215 7.00855 5.37499 7.00237 5.43209 6.97871C5.48919 6.95506 5.538 6.91501 5.57233 6.86362C5.60667 6.81223 5.625 6.75181 5.625 6.69C5.625 6.60712 5.59208 6.52764 5.53347 6.46903C5.47487 6.41043 5.39538 6.3775 5.3125 6.3775Z"
      fill="#80ADAF"
      stroke="#80ADAF"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9375 9.5C15.8757 9.5 15.8153 9.51833 15.7639 9.55267C15.7125 9.587 15.6724 9.63581 15.6488 9.69291C15.6251 9.75001 15.6189 9.81285 15.631 9.87347C15.6431 9.93409 15.6728 9.98977 15.7165 10.0335C15.7602 10.0772 15.8159 10.1069 15.8765 10.119C15.9372 10.1311 16 10.1249 16.0571 10.1012C16.1142 10.0776 16.163 10.0375 16.1973 9.98612C16.2317 9.93473 16.25 9.87431 16.25 9.8125C16.25 9.72962 16.2171 9.65013 16.1585 9.59153C16.0999 9.53292 16.0204 9.5 15.9375 9.5Z"
      fill="#80ADAF"
      stroke="#80ADAF"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.6875 6.3775C14.6257 6.3775 14.5653 6.39583 14.5139 6.43017C14.4625 6.46451 14.4224 6.51331 14.3988 6.57041C14.3751 6.62751 14.3689 6.69035 14.381 6.75097C14.3931 6.81159 14.4228 6.86727 14.4665 6.91097C14.5102 6.95468 14.5659 6.98444 14.6265 6.9965C14.6872 7.00855 14.75 7.00237 14.8071 6.97871C14.8642 6.95506 14.913 6.91501 14.9473 6.86362C14.9817 6.81223 15 6.75181 15 6.69C15 6.60712 14.9671 6.52764 14.9085 6.46903C14.8499 6.41043 14.7704 6.3775 14.6875 6.3775Z"
      fill="#80ADAF"
      stroke="#80ADAF"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.8125 3.8775C7.75069 3.8775 7.69027 3.89583 7.63888 3.93017C7.58749 3.96451 7.54744 4.01331 7.52379 4.07041C7.50014 4.12751 7.49395 4.19035 7.506 4.25097C7.51806 4.31159 7.54782 4.36727 7.59153 4.41097C7.63523 4.45468 7.69091 4.48444 7.75153 4.4965C7.81215 4.50855 7.87499 4.50237 7.93209 4.47871C7.98919 4.45506 8.038 4.41501 8.07233 4.36362C8.10667 4.31223 8.125 4.25181 8.125 4.19C8.125 4.10712 8.09208 4.02764 8.03347 3.96903C7.97487 3.91043 7.89538 3.8775 7.8125 3.8775Z"
      fill="#80ADAF"
      stroke="#80ADAF"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DashboardIcon',

    props: {
      width: {
        type: Number,
        default: 20,
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'dashboard-icon';

  .#{$module} {
    display: block;
    height: auto;

    path {
      stroke: var(--color-grey-600);
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
</style>
