<template>
  <i
    v-if="stateClass"
    class="field-state-icons fas"
    :class="[stateClass]"
  ></i>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'FieldStateIcons',

    props: {
      state: {
        type: String,
        default: '',
      },
    },

    computed: {
      stateClass() {
        const { state } = this

        if (state === 'success') return 'fa-check'

        if (state === 'failure') return 'fa-exclamation-triangle'

        return ''
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'field-state-icons';

  .#{$module} {
    font-weight: 400;
    &.fa-exclamation-triangle {
      color: var(--color-red-700);
    }

    &.fa-check {
      color: var(--color-green-500);
    }
  }
</style>
