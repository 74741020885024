// eslint-disable-next-line max-classes-per-file
import { IsArray, IsBoolean, IsNumber, IsString, IsUUID, ValidateNested } from 'class-validator'

import { Type } from 'class-transformer'

import { ValidateDTO } from '..'

import { TOTAL_PRODUCT_QUANTITY_CONTROLLER } from './controller.common-vars'

export const UPDATE_TOTAL_PRODUCT_QUANTITY_SUBPATH = '/'
export const UPDATE_TOTAL_PRODUCT_QUANTITY_URI = `${TOTAL_PRODUCT_QUANTITY_CONTROLLER}${UPDATE_TOTAL_PRODUCT_QUANTITY_SUBPATH}`

export class UpdateTotalProductQuantityRecords {
  @IsString()
  facilityId!: string

  @IsNumber()
  year!: number

  @IsString()
  totalProductUnit!: string

  @IsNumber()
  totalProductQuantity!: number

  @IsBoolean()
  isEstimatedAmount!: boolean
}

export class UpdateTotalProductQuantityReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsArray()
  @ValidateNested()
  @Type(() => UpdateTotalProductQuantityRecords)
  records!: UpdateTotalProductQuantityRecords[]
}

export type UpdateTotalProductQuantityRes = {
  success: boolean
}
