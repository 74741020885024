import { IsOptional, IsString } from 'class-validator'

export class BasicOnboardingUserDTO {
  @IsString()
  name!: string

  @IsString()
  email!: string

  @IsString()
  @IsOptional()
  password?: string
}
