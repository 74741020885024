<template>
  <div
    ref="parent"
    :class="[
      'dropdown',
      { 'dropdown--expanded': expanded, 'dropdown--paginated': isPaginated, 'dropdown--select': variant === 'select' },
      { open: open || isDropdownOpen },
    ]"
    @click="onClick"
    @keydown.esc="close"
    @keydown.up="open = true"
    @keydown.down="open = true"
    @keydown.left="open = true"
    @keydown.right="open = true"
  >
    <slot name="parent" />

    <div class="dropdown__trigger-box">
      <component
        :is="triggerElement"
        ref="trigger"
        :type="triggerElement === 'button' ? 'button' : ''"
        :class="[
          'dropdown__trigger',
          {
            opened: open || isDropdownOpen,
            'dropdown__trigger--select': variant === 'select',
            'dropdown__trigger--selected': hasSelection,
          },
        ]"
        :disabled="isDisabled"
        data-ci="dropdown-trigger"
        @click.stop="open = !open"
      >
        <slot name="trigger" />
        <div class="dropdown__trigger-chevron"><i class="far fa-angle-down"></i></div>
      </component>

      <slot name="custom" />
    </div>

    <transition name="fade">
      <Teleport
        to="body"
        :disabled="!enableTeleport"
      >
        <div
          v-if="open || isDropdownOpen"
          ref="dropdown"
          :class="[
            'dropdown__items-wrap',
            position,
            {
              searchable: isSearchable,
              teleported: props.enableTeleport && (open || isDropdownOpen),
            },
          ]"
          :style="dropdownStyle"
          data-selector="dropdown-items-wrap"
          @click="onClick"
        >
          <component
            :is="isSearchable ? Card : 'div'"
            :smallestPadding="isSearchable"
          >
            <p
              v-if="label"
              class="dropdown__label"
            >
              {{ label }}
            </p>
            <AqInput
              v-if="isSearchable"
              ref="search"
              v-model="activeSearchTermModel"
              class="dropdown__search"
              name="dropdown-search"
              data-ci="dropdown-search-input"
              :autofocus="true"
              :showSearchIcon="true"
              :placeholder="searchPlaceholder"
              :includeMargin="includeMarginForSearchInput"
              :disabled="searchDisabled"
            ></AqInput>
            <component
              :is="itemsWrapperElement"
              :class="[
                'dropdown__items',
                {
                  'has-min-width': minWidthItems,
                  loading: isLoading,
                },
              ]"
              data-scroll="list-items"
              data-ci="dropdown-items"
              @blur="close"
              @scroll="onScrollPaginatedList"
            >
              <slot />
              <li
                v-if="isPaginated"
                :class="['dropdown__loader', { 'dropdown__loader--invisible': !isLoading }]"
              >
                <AqLoader
                  color="kelp"
                  variant="circle"
                />
              </li>

              <div
                v-if="$slots.sticky"
                class="sticky"
              >
                <slot name="sticky" />
              </div>
            </component>
          </component>
        </div>
      </Teleport>
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { ref, watch, computed, reactive, onMounted, type StyleValue, onBeforeUnmount } from 'vue'
  import { useRoute } from 'vue-router'
  import { onClickOutside, useElementBounding } from '@vueuse/core'
  import { debounce } from 'lodash-es'

  import AqInput from '@components/designsystem/AqInput/AqInput.vue'
  import Card from '@components/designsystem/Card/Card.vue'
  import AqLoader from '@components/designsystem/AqLoader/AqLoader.vue'

  import { useListKeyboardNavigation } from '@/imports/lib/utilities/composables/listKeyboardNavigation'

  type Props = {
    closeOnRouteChange?: boolean
    closeOnClick?: boolean
    isDropdownOpen?: boolean
    isDisabled?: boolean
    triggerElement?: string
    isSearchable?: boolean
    isPaginated?: boolean
    isLoading?: boolean
    activeSearchTerm?: string
    searchDisabled?: boolean
    searchPlaceholder?: string
    expanded?: boolean
    label?: string
    minWidthItems?: boolean
    itemsWrapperElement?: string
    enableTeleport?: boolean
    includeMarginForSearchInput?: boolean
    includeMinWidth?: boolean
    includeMaxWidth?: boolean
    variant?: 'unstyled' | 'select'
    hasSelection?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    triggerElement: 'button',
    activeSearchTerm: '',
    searchPlaceholder: 'Search',
    minWidthItems: true,
    itemsWrapperElement: 'ul',
    label: '',
    includeMarginForSearchInput: true,
    searchDisabled: false,
    includeMinWidth: true,
    includeMaxWidth: false,
    variant: 'unstyled',
    hasSelection: false,
  })

  const emit = defineEmits<{
    (event: 'onEnter', selectedIndex: number): void
    (event: 'onClose'): void
    (event: 'onOpen'): void
    (event: 'update:activeSearchTerm', value: string): void
    (event: 'fetchData'): void
  }>()

  const open = ref(props.isDropdownOpen)
  const search = ref<typeof AqInput | null>(null)
  const parent = ref<HTMLElement | null>(null)
  const route = props.closeOnRouteChange ? useRoute() : null
  const position = ref<'left' | 'right'>('left')
  const trigger = ref<HTMLElement | null>(null)
  const triggerPosition = props.enableTeleport
    ? reactive(useElementBounding(trigger, { windowResize: true, windowScroll: true }))
    : ref(undefined)
  const dropdown = ref<HTMLElement | null>(null)
  const dropdownStyle = ref<StyleValue>()

  // Computed property for the active search term to handle update events
  const activeSearchTermModel = computed({
    get(): string {
      return props.activeSearchTerm
    },
    set(value: string) {
      emit('update:activeSearchTerm', value)
    },
  })

  const close = () => {
    open.value = false
    emit('onClose')
  }

  onClickOutside(dropdown, close, { ignore: [parent] })

  if (props.closeOnRouteChange && route) watch(() => route.name, close)

  /**
   * Emit selected list item from Enter submit to parent component
   */
  const selectByKeyboard = (selectedIndex: number) => {
    emit('onEnter', selectedIndex)

    if (props.closeOnClick && open.value) close()
  }

  useListKeyboardNavigation(parent, open, selectByKeyboard)

  /**
   * If we're within 200px of the right edge of the viewport we
   * add a class and show the dropdown on the left of the parent.
   *
   * TODO: This could still cause issues because if the content is
   * really long it could need to be 500px or 1000px or whatever.
   * It needs to be based on the content width. So might need to be
   * on the items and updated on open.
   */
  const isElementNearRightEdge = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect()
    const viewportWidth = window.innerWidth
    const elementRight = rect.right

    return elementRight >= viewportWidth - 200
  }

  const onClick = (event: MouseEvent) => {
    if (!(trigger.value instanceof HTMLElement)) return

    const triggerWasClicked = event.currentTarget === trigger.value || trigger.value?.contains(event.target as Node)
    const searchWasClicked = event.currentTarget === search.value?.$el || search.value?.$el.contains(event.target)

    if (!triggerWasClicked && !searchWasClicked && props.closeOnClick && open.value) close()
  }

  const calculateTeleportedDropdownStyle = (triggerEl: Element): StyleValue | undefined => {
    const triggerBox = triggerEl.getBoundingClientRect()

    // get height of dropdown element
    const dropDownElDims = dropdown.value?.getBoundingClientRect()
    const maxHeightOfList = dropDownElDims?.height || 400

    const margin = 8
    const distanceFromViewportBottom = window.innerHeight - triggerBox.bottom

    let closeToBottom = distanceFromViewportBottom < maxHeightOfList
    const closeToTop = triggerBox.top < maxHeightOfList

    if (closeToBottom && closeToTop) {
      closeToBottom = false
    }

    const isLeft = position.value === 'left'
    const isRight = !isLeft

    const top = !closeToBottom ? `${triggerBox.top + window.scrollY + triggerBox.height + margin}px` : 'auto'
    const bottom = closeToBottom ? `${distanceFromViewportBottom + triggerBox.height + margin}px` : 'auto'
    const left = isLeft ? `${triggerBox.left}px` : 'auto'
    const right = isRight ? `${window.innerWidth - triggerBox.right}px` : 'auto'

    return {
      position: 'absolute',
      top,
      bottom,
      left,
      right,
      minWidth: props.includeMinWidth ? `${triggerBox.width}px` : 'min-content',
      maxWidth: props.includeMaxWidth ? `${triggerBox.width}px` : 'max-content',
    }
  }

  // Pagination

  const fetchData = () => {
    if (props.isLoading) return

    emit('fetchData')
  }

  const onScrollPaginatedList = debounce((event: UIEvent) => {
    if (!props.isPaginated) return

    if (event.target instanceof HTMLElement) {
      const el = event.target

      // Check if the element's scroll position is within 100 pixels from the maximum
      const isNearBottom = el.scrollHeight - el.scrollTop - el.clientHeight < 1000

      if (isNearBottom) {
        setTimeout(fetchData)
      }
    }
  }, 150)

  const positionDropDown = () => {
    if (trigger.value && open.value) dropdownStyle.value = calculateTeleportedDropdownStyle(trigger.value)
  }

  // ------------

  onMounted(() => {
    /**
     * If our items are too close to the right of the viewport we
     * switch the position so that we don't overlap the  boundary
     */
    if (parent.value instanceof HTMLElement) {
      position.value = isElementNearRightEdge(parent.value) ? 'right' : 'left'
    }

    if (props.enableTeleport) {
      window.addEventListener('resize', positionDropDown)
    }
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', positionDropDown)
  })

  watch(
    () => props.isDropdownOpen,
    value => {
      open.value = value
    },
  )

  watch(open, isOpen => {
    if (isOpen) {
      emit('onOpen')

      if (trigger.value && props.enableTeleport) {
        positionDropDown()
      }
    } else {
      emit('onClose')
    }
  })

  watch(triggerPosition, () => {
    if (props.enableTeleport) {
      positionDropDown()
    }
  })
</script>

<style lang="scss">
  $module: 'dropdown';

  .#{$module} {
    display: inline-flex;
    position: relative;
    vertical-align: top;

    &--expanded,
    &--select,
    &--expanded &__trigger-box,
    &--expanded &__trigger {
      width: 100%;
    }

    &__items-wrap {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(100% + 8px);
      min-width: min-content;
      box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      z-index: 20;

      &:not(.searchable) {
        border: 1px solid var(--color-grey-300);
        background-color: var(--color-grey-0);
      }

      &.searchable {
        min-width: 320px;
      }

      &.right {
        left: auto;
      }
    }

    &__items-wrap &__items > .aq-loader > .aq-loader__spinner {
      position: unset;
      transform: unset;
    }

    &__items {
      max-height: 300px;
      width: 100%;
      overflow: auto;
      scrollbar-width: none;
      min-width: 160px;
      border-radius: 8px;

      &::-webkit-scrollbar {
        display: none;
      }

      &.has-min-width {
        min-width: min-content;
      }

      &:not(.#{$module}__items-wrap.searchable &) {
        overflow: auto;
      }

      &.loading {
        min-height: 240px;
        position: relative;

        .#{$module}__loader:first-child {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          margin-top: 0;
        }
      }
    }

    &__trigger {
      padding: 0;
      cursor: pointer;
      outline: none;
      width: 100%;
      height: 100%;

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &__trigger--select {
      display: flex;
      justify-content: space-between;
      border-radius: $radius;
      border: 1px solid var(--color-grey-300);
      background: var(--color-grey-0);
      padding: $grid-size-padding * 2 $grid-size-padding * 4;
      width: 100%;
      font-size: $font-size-6;
      line-height: math.div(24, 16);
      color: var(--color-grey-400);
    }

    &__trigger--selected {
      color: var(--color-grey-600);
    }

    &__trigger-box {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__trigger-chevron {
      display: none;
      margin-left: $grid-size-margin;
      color: var(--color-grey-600);

      .#{$module}__trigger--select & {
        display: block;
      }
    }

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: $grid-size-margin * 2;
      opacity: 1;
      transition:
        opacity 0.15s linear,
        height 0.15s linear,
        margin-top 0.15s linear;
    }

    &__loader--invisible {
      margin-top: 0px;
      opacity: 0;
      height: 0px;
    }

    .sticky {
      position: sticky;
      bottom: 0;
      background-color: white;
    }

    &__label {
      font-size: $font-size-6;
      font-weight: 500;
      margin-bottom: $grid-size-padding * 3;
    }
  }
</style>
