// eslint-disable-next-line max-classes-per-file
import { IsArray, IsNumber, IsString, IsUUID, ValidateNested } from 'class-validator'

import { Type } from 'class-transformer'

import { ValidateDTO } from '..'

import { TOTAL_PRODUCT_QUANTITY_CONTROLLER } from './controller.common-vars'

export const DELETE_TOTAL_PRODUCT_QUANTITY_SUBPATH = '/'
export const DELETE_TOTAL_PRODUCT_QUANTITY_URI = `${TOTAL_PRODUCT_QUANTITY_CONTROLLER}${DELETE_TOTAL_PRODUCT_QUANTITY_SUBPATH}`

export class DeleteTotalProductQuantityRecords {
  @IsString()
  facilityId!: string

  @IsNumber()
  year!: number
}
export class DeleteTotalProductQuantityReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsArray()
  @ValidateNested()
  @Type(() => DeleteTotalProductQuantityRecords)
  records!: DeleteTotalProductQuantityRecords[]
}

export type DeleteTotalProductQuantityRes = {
  success: boolean
}
